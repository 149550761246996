<mat-card class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
  <div class="panel-body m-2 p-2"  #FILTRO style="flex-direction: row">

    <div class="flex flex-col gap-4 px-2">
      <p class="text-blue-950 text-2xl font-bold">Ficha Docente</p>
      <div class="filtros form-group row py-2 px-4 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
        <div class="flex flex-row gap-1 py-2">
          <mat-icon>filter_list</mat-icon>
          <p class="text-lg font-bold">Filtros</p>
        </div>

        <div class="row" aria-label="sección de inputs">
          <div class="col-lg-3 lg:col-xl-3 col-md-6 col-sm-6 col-xs-12 col-xl-3 ">
            <mat-form-field class="w-full">
              <mat-label>RUT Docente</mat-label>
              <input type="rut" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345678" [(ngModel)]="filtros.rutDocente" name="rutDocente" />
            </mat-form-field>
          </div>

          <div class="col-lg-3 lg:col-xl-3 col-md-6 col-sm-6 col-xs-12 col-xl-3 ">
            <mat-form-field class="w-full">
              <mat-label>RUT Sostenedor</mat-label>
              <input type="rut" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345678" [(ngModel)]="filtros.rutSostenedor" name="rutSostenedor" />
            </mat-form-field>
          </div>
          <div class="col-lg-3 lg:col-xl-3 col-md-6 col-sm-6 col-xs-12 col-xl-3 ">
            <mat-form-field class="w-full">
              <mat-label>RBD</mat-label>
              <input type="number" matInput onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                placeholder="12345" class="fullWidth" [(ngModel)]="filtros.rbd" />

            </mat-form-field>
          </div>
          <div class="col-lg-3 lg:col-xl-3 col-md-6 col-sm-6 col-xs-12 col-xl-3 ">
            <mat-form-field class="w-full">
              <mat-label class="fullWidth">Estado de Inscripción</mat-label>

              <mat-select [(ngModel)]="filtros.estadoRegistro" placeholder="Estado de Inscripción">
                <mat-option [value]=""></mat-option>
                <mat-option *ngFor="let item of estadosInscripcion" [value]="item">{{item}}</mat-option>
              </mat-select>

            </mat-form-field>
          </div>

          <div class="col-lg-3 lg:col-xl-3 col-md-6 col-sm-6 col-xs-12 col-xl-3 ">
            <mat-form-field class="w-full">
              <mat-label class="fullWidth">Estado de Validación</mat-label>

              <mat-select [(ngModel)]="filtros.estadoValidacion" placeholder="Estado de Validación">
                <mat-option [value]="">Todos</mat-option>
                <mat-option *ngFor="let item of estadosValidacion" [value]="item.value">{{item.label}}</mat-option>
              </mat-select>

            </mat-form-field>
          </div>

        </div>

        <div class="d-flex" aria-describedby="footer filtro">
          <div aria-labelledby="botones inf. izq" class="float-left d-flex  align-self-start ">
            <mat-card-actions class="d-flex row">
              <button
                type="button"
                data-te-ripple-init
                (click)="filtrar()"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                <p class="pr-1"></p> Filtrar
              </button>
              <button
                type="button"
                data-te-ripple-init
                (click)="limpiar()"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center ajustar items-center rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
                <p class="pr-1"></p> Limpiar
              </button>
              <button
                type="button"
                *ngIf="permisoDescargaDocentesCSV"
                data-te-ripple-init
                (click)="descarga()"
                data-te-ripple-color="light"
                class="d-flex w-40  mx-2 justify-items-center justify-center text-center ajustar items-center rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <fa-icon *ngIf="isLoading" class="pr-2" [icon]="faSpinner" disabled animation="spin"></fa-icon>
                <svg *ngIf="!isLoading" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="pr-2" viewBox="0 0 16 16"> <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/> <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/> </svg>
                <p class="pr-1"></p> CSV Docentes
              </button>
            </mat-card-actions>
          </div>
          <div class="float-right ms-auto my-auto d-flex justify-content-end">
            <mat-card-actions>
              <div class="dropdown">
                <button color="primary"
                data-te-ripple-color="light"
                type="button"
                data-te-ripple-init
                class="flex w-60 justify-items-center justify-center text-center items-center rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                  id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false" style="float: right;">
                  <svg xmlns="http://www.w3.org/2000/svg" class="px-2" width="16" height="16" fill="currentColor" class="bi bi-layout-three-columns" viewBox="0 0 16 16"> <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0h13A1.5 1.5 0 0 1 16 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13zM1.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5H5V1H1.5zM10 15V1H6v14h4zm1 0h3.5a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5H11v14z"/> </svg>
                  <p class="px-1"></p> Editar columnas <p class="px-1"></p>
                  <svg xmlns="http://www.w3.org/2000/svg" class="px-2"width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/> </svg>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <div class="container" style="width: 400px;">
                    <div class="row">
                      <div class="col" style="flex-direction: row;">
                        <div *ngFor="let item of toggleColumns" class="form-check">
                          <input [(ngModel)]="item.value" checked class="form-check-input" type="checkbox"
                            [id]="item.key">
                          <label class="form-check-label" [for]="item.key">{{item.name}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-actions>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="overflow-x-scroll rounded-2xl border border-gray-200 m-2 p-2" aria-label="sección tabla" >
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading" />
    <table class="w-full table-auto table-hover rounded-2xl table bg-gray-50" role="table">
      <thead class="bg-blue-700 ">
        <tr>
          <th scope="col" class="rounded-tl-2xl pl-2 pr-4 py-3 text-left text-blue-100 text-sm font-black " >{{nameDisplayedColumns[0]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  *ngIf="toggleColumns[0].value">Nombre Completo</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  *ngIf="toggleColumns[2].value">Contacto</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  *ngIf="toggleColumns[3].value"><p class="text-sm font-black">Establecimiento</p> <p class="text-sm font-black">& RBD</p></th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  *ngIf="toggleColumns[4].value">Entidad Sostenedora</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  *ngIf="toggleColumns[5].value">{{nameDisplayedColumns[10]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  *ngIf="toggleColumns[6].value">{{nameDisplayedColumns[11]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " >{{nameDisplayedColumns[12]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black " >{{nameDisplayedColumns[13]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  *ngIf="toggleColumns[7].value">{{nameDisplayedColumns[14]}}</th>
          <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  *ngIf="toggleColumns[8].value">{{nameDisplayedColumns[15]}}</th>
          <th scope="col" class="rounded-tr-2xl pr-4 py-3 text-left text-blue-100 text-sm font-black " >{{nameDisplayedColumns[16]}}</th>
        </tr>
      </thead>
      <tbody class="divide-y divide-gray-200">
        <tr class="min-w-full" *ngFor="let element of response">
          <td class="negrita-tabla" #rut>
            <div style="min-width: 130px;">
              <button data-bs-toggle="modal" data-bs-target="#modalInformacionDelDocente" type="button"
                class="inline-block rounded-xl border-2 border-primary px-6 pb-[6px] pt-2 text-[14px] font-bold uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 "
                (click)="obtenerDocente(this.element)">
                {{ element.rutDocente +'-'+ element.dvDocente}}</button></div>
          </td>
          <td class="negrita-tabla border-0" #nombres *ngIf="toggleColumns[0].value">
            <div class="flex flex-col">
              <p class="text-xs uppercase">{{element.nombres ? element.nombres :'-'}}</p>
              <p class="text-xs uppercase">{{ element.primerApellido? element.primerApellido : '-' }}</p>
              <p class="text-xs uppercase">{{element.segundoApellido ? element.segundoApellido : '-' }}</p>
            </div>
          </td>
          <td class="negrita-tabla" #contacto *ngIf="toggleColumns[2].value" matTooltip={{element.email}}>
            <div class="flex flex-col max-w-[150px]">
              <div class="text-xs flex flex-row gap-2 ">
                <svg xmlns="http://www.w3.org/2000/svg"  width="16" height="16" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg>
                <p class="truncate-cell lowercase text-xs">{{element.email}}</p>
              </div>
              <p class="text-xs flex flex-row gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone-vibrate" viewBox="0 0 16 16"> <path d="M10 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4zM6 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6z"/> <path d="M8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM1.599 4.058a.5.5 0 0 1 .208.676A6.967 6.967 0 0 0 1 8c0 1.18.292 2.292.807 3.266a.5.5 0 0 1-.884.468A7.968 7.968 0 0 1 0 8c0-1.347.334-2.619.923-3.734a.5.5 0 0 1 .676-.208zm12.802 0a.5.5 0 0 1 .676.208A7.967 7.967 0 0 1 16 8a7.967 7.967 0 0 1-.923 3.734.5.5 0 0 1-.884-.468A6.967 6.967 0 0 0 15 8c0-1.18-.292-2.292-.807-3.266a.5.5 0 0 1 .208-.676zM3.057 5.534a.5.5 0 0 1 .284.648A4.986 4.986 0 0 0 3 8c0 .642.12 1.255.34 1.818a.5.5 0 1 1-.93.364A5.986 5.986 0 0 1 2 8c0-.769.145-1.505.41-2.182a.5.5 0 0 1 .647-.284zm9.886 0a.5.5 0 0 1 .648.284C13.855 6.495 14 7.231 14 8c0 .769-.145 1.505-.41 2.182a.5.5 0 0 1-.93-.364C12.88 9.255 13 8.642 13 8c0-.642-.12-1.255-.34-1.818a.5.5 0 0 1 .283-.648z"/> </svg>
                {{element.numeroContacto1}}</p>
              <p class="text-xs flex flex-row gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone-vibrate" viewBox="0 0 16 16"> <path d="M10 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4zM6 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H6z"/> <path d="M8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2zM1.599 4.058a.5.5 0 0 1 .208.676A6.967 6.967 0 0 0 1 8c0 1.18.292 2.292.807 3.266a.5.5 0 0 1-.884.468A7.968 7.968 0 0 1 0 8c0-1.347.334-2.619.923-3.734a.5.5 0 0 1 .676-.208zm12.802 0a.5.5 0 0 1 .676.208A7.967 7.967 0 0 1 16 8a7.967 7.967 0 0 1-.923 3.734.5.5 0 0 1-.884-.468A6.967 6.967 0 0 0 15 8c0-1.18-.292-2.292-.807-3.266a.5.5 0 0 1 .208-.676zM3.057 5.534a.5.5 0 0 1 .284.648A4.986 4.986 0 0 0 3 8c0 .642.12 1.255.34 1.818a.5.5 0 1 1-.93.364A5.986 5.986 0 0 1 2 8c0-.769.145-1.505.41-2.182a.5.5 0 0 1 .647-.284zm9.886 0a.5.5 0 0 1 .648.284C13.855 6.495 14 7.231 14 8c0 .769-.145 1.505-.41 2.182a.5.5 0 0 1-.93-.364C12.88 9.255 13 8.642 13 8c0-.642-.12-1.255-.34-1.818a.5.5 0 0 1 .283-.648z"/> </svg>
                {{element.numeroContacto2}}</p>
            </div>
          </td>

          <td class="negrita-tabla" #rbdEstabl *ngIf="toggleColumns[3].value" matTooltip={{element.nombreEstablecimiento}}>
            <div class="flex flex-col max-w-[150px]">
              <p class="truncate-cell capitalize text-xs">{{element.nombreEstablecimiento}}</p>
              <p class="truncate-cell capitalize text-xs">{{element.rbd}}</p>
            </div>

          </td>
          <td class="negrita-tabla" #sostenedor *ngIf="toggleColumns[4].value">
            <div class="flex flex-col max-w-[150px]" matTooltip={{element.nombreSostenedor}}>
              <p class="truncate-cell capitalize text-xs">{{element.nombreSostenedor}}</p>
              <p class="truncate-cell capitalize text-xs">{{element.rutSostenedor}}</p>
            </div>
          </td>
          <td class="negrita-tabla" *ngIf="toggleColumns[5].value">
            <div *ngIf="element.estadoRegistro=='Desinscrito'"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-backspace" viewBox="0 0 16 16"> <path d="M5.83 5.146a.5.5 0 0 0 0 .708L7.975 8l-2.147 2.146a.5.5 0 0 0 .707.708l2.147-2.147 2.146 2.147a.5.5 0 0 0 .707-.708L9.39 8l2.146-2.146a.5.5 0 0 0-.707-.708L8.683 7.293 6.536 5.146a.5.5 0 0 0-.707 0z"/> <path d="M13.683 1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-7.08a2 2 0 0 1-1.519-.698L.241 8.65a1 1 0 0 1 0-1.302L5.084 1.7A2 2 0 0 1 6.603 1h7.08zm-7.08 1a1 1 0 0 0-.76.35L1 8l4.844 5.65a1 1 0 0 0 .759.35h7.08a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1h-7.08z"/> </svg></div>
            <div *ngIf="element.estadoRegistro=='En Revisión'" class="text-orange-500"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg></div>
            <div *ngIf="element.estadoRegistro=='Pendiente'" class="text-gray-400"><img width="24" height="24" src="../../../../assets/images/content/pending.svg" alt="pending"></div>
            <div *ngIf="element.estadoRegistro=='Inscrito'" class="text-green-600"><svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7 12.5L10 15.5L17 8.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg></div>
            <div *ngIf="element.estadoRegistro=='Cancelado'" class="text-red-400"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"/> </g> </svg></div>
            <p class="text-sm">{{element.estadoRegistro}}</p></td>
          <td class="negrita-tabla truncate-cell" *ngIf="toggleColumns[6].value" matTooltip="{{element.debeRendir}}">{{element.debeRendir}}</td>
          <td class="negrita-tabla">{{element.codNivel}}</td>
          <td class="negrita-tabla">{{element.codSubsector}}</td>
          <td class="negrita-tabla flex flex-col gap-1 border-0 h-full" *ngIf="toggleColumns[7].value">
            <svg *ngIf="element.flagValidacion == false"  style="color: red" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16"> <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" fill="red"></path> </svg>
            <div *ngIf="element.flagValidacion == true" class="text-green-600"><svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7 12.5L10 15.5L17 8.5" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg></div>
            <div *ngIf="element.flagValidacion == null" class="text-red-400"><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"/> </g> </svg></div>

            <p class="text-sm max-w-[150px] break-words">
              {{!element.flagValidacion ? "No Validado" : "Validado" }}
          </td>
          <td class="negrita-tabla">
            <div class="atras">
              <div class="dropleft dropdown-container">
                <button
                  type="button"
                  class="dropdown-toggle dropdown-toggle-split inline-block rounded-xl border-2 border-primary px-6 pb-[6px] pt-2 text-[14px] font-medium uppercase leading-normal text-primary transition duration-150 ease-in-out hover:border-primary-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-primary-600 focus:border-primary-600 focus:text-primary-600 focus:outline-none focus:ring-0 active:border-primary-700 active:text-primary-700 "
                  data-bs-toggle="dropdown" aria-haspopup="true" data-boundary="window" aria-expanded="false">
                  <span class="sr-only">Toggle Dropleft</span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#modalPDF"
                    (click)="setUrl(element.rutDocente)"> Ver notificación de Participación </a>
                  <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#modalInfoSuspenderEximir"
                    (click)="getInfoSuspenderEximir(element.rutDocente, element)">Ver información Suspender/Eximir</a>
                  <a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#modalEditarDocente"
                    (click)="setInfoEditarDocente(element.rutDocente, this.element)" *ngIf="permisoEdicionDocentes">
                    Editar Docente </a>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [showFirstLastButtons]="true" [length]="length"
    [pageIndex]="pageIndex" (page)="pageChanged($event)" aria-label="Select page of periodic elements">
  </mat-paginator>
</mat-card>

<!-- Modal Editar Docente -->
<div class="modal fade modalEdicion" id="modalEditarDocente" tabindex="-1"  data-te-smooth-scroll-init
aria-labelledby="modalInformacionDelDocenteLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-lg">
    <div class="modal-content" *ngIf="modalEditarDocenteOn">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="modalInformacionDelDocenteLabel">Editar Docente RUT:
          <b>{{rutInfoSuspender}}</b>
        </h1>

        <button type="button" data-bs-dismiss="modal" (click)="cerrarModalEdicion()" aria-label="Close"
          class="rounded-md p-2 inline-flex items-center justify-center text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
          <span class="sr-only">Close menu</span>
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="modal-body" *ngIf="editarInfoDocente && acordeonesEditarDocente.length>0">

        <acordeon-tw
          [accordeonParent]="acordeonesEditarDocenteName"
          [acordeones]="acordeonesEditarDocente"></acordeon-tw>
      </div>
      <div class="modal-footer modal-footer--sticky mx-4 d-flex flex-col justify-content-end">
        <div *ngIf="(editarDocenteFormGroup.invalid || !establecimientoSeleccionado) && this.verificarAtributosNulos().length > 0"
          class="w-full h-full flex flex-col justify-center items-center py-2 my-2 border-2 rounded-xl border-red-800 text-xs">
          <div class="w-full text-center text-sm">
            Campos faltantes y <u class="text-sm font-bold text-red-800">obligatorios</u>:
          </div>
          <div class="flex flex-col items-center mt-2">
            <div *ngFor="let item of atributosNulosOVacios"
                  class="text-xs flex flex-row"
                  [ngClass]="{'text-red-800 font-bold': nombreCampos[item] && nombreCampos[item].includes('*')}">
              {{nombreCampos[item]}}
            </div>
          </div>
        </div>

        <div class=" flex justify-content-end">
          <button mat-raised-button color="secondary" #cerrarModal id="cerrarModal" class="mx-2"
          data-target="#modalEditarDocente" data-bs-dismiss="modal" (click)="cerrarModalEdicion()">Cancelar</button>
        <button mat-raised-button color="primary" (click)="guardarEdicionDocente()" data-target="#modalEditarDocente"
          data-bs-dismiss="modal" [disabled]="editarDocenteFormGroup.invalid || !establecimientoSeleccionado">Guardar
          Cambios</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editarDatosPersonales
  let-editarDocenteFormGroup="editarDocenteFormGroup"
  let-formularioDatosPersonales="formularioDatosPersonales">
  <div class="accordion-body p-4" [formGroup]="editarDocenteFormGroup">
    <div class="row" *ngFor="let item of formularioDatosPersonales; let i=index">
      <div class="col-3 text-left">
        <label class="labelForm text-sm" for="item.variable">{{item.etiqueta}}</label>
      </div>
      <div class="col-9">
        <mat-form-field [style.fontSize.px]=14 appearance="outline" class="example-full-width fullWidth">
          <input matInput type={{item.tipoDato}} placeholder={{item.etiqueta}}
            formControlName={{item.variable}} name={{item.variable}} readonly={{item.esReadOnly}} />
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editarDatosContacto
  let-editarDocenteFormGroup="editarDocenteFormGroup"
  let-estadoDocenteSeleccionado="estadoDocenteSeleccionado"
  let-formularioDatosContacto="formularioDatosContacto">
  <div class="accordion-body p-4" [formGroup]="editarDocenteFormGroup">
    <div *ngIf="estadoDocenteSeleccionado">
      <div class="row" *ngFor="let item of formularioDatosContacto; let i=index">
        <div class="col-3 text-left">
          <label class="labelForm text-sm" for="item.variable">{{item.etiqueta}}</label>
        </div>
        <div class="col-9">
          <mat-form-field appearance="outline" class="example-full-width fullWidth">
            <input matInput type={{item.tipoDato}} placeholder={{item.etiqueta}}
              formControlName={{item.variable}} name={{item.variable}} readonly={{item.esReadOnly}} />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div *ngIf="!estadoDocenteSeleccionado" class="p-2">
      <p>No se puede editar los datos de contacto del docente porque no está validado.</p>
    </div>
  </div>
</ng-template>

<ng-template #editarEstablecimiento let-this
  >
  <div class="accordion-body p-4" [formGroup]="this.editarDocenteFormGroup">
    <div class="row" *ngFor="let item of this.formularioDatosEstablecimiento; let i=index">
      <div class="col-3 text-left">
        <label class="labelForm text-sm" for="item.variable">{{item.etiqueta}}</label>
      </div>
      <div class="col-9">
        <mat-form-field *ngIf="i!=0" appearance="outline" class="example-full-width fullWidth">
          <input matInput type={{item.tipoDato}} placeholder={{item.etiqueta}}
            formControlName={{item.variable}} name={{item.variable}} readonly={{item.esReadOnly}} />
        </mat-form-field>

        <div *ngIf="i==0">
          <div *ngIf="!this.listaRBD" class="fullWidth"> Cargando Establecimientos </div>
          <div *ngIf="this.listaRBD" class="fullWidth">
            <form class="example-form fullWidth">
              <mat-form-field appearance="outline" class="example-full-width fullWidth">
                <input type="text" appearance="outline" placeholder="Seleccione Establecimiento"
                  aria-label="Number" matInput [formControl]="this.rbdControl"
                  (ngModelChange)="this.searchRbd($event)"
                  [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [matAutocomplete]="auto"
                  (optionSelected)="this.setRbd($event.option.value)">
                  <mat-option class="optionsAutocomplete" *ngFor="let option of this.listaRBDFiltrada"
                    [value]="this.option.rbd">
                    {{option.rbd}} - {{option.nombre}}
                  </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix>expand_more</mat-icon>
                <mat-hint *ngIf="this.establecimientoSeleccionado==null && this.listaRBDFiltrada.length>0"
                  style="color: brown;">Seleccione un RBD de la lista.</mat-hint>
                <mat-hint *ngIf="this.listaRBDFiltrada.length==0" style="color: brown;">Ingrese un RBD
                  válido.</mat-hint>

              </mat-form-field>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #editarInscripcion
  let-this>
  <div class="accordion-body p-4" [formGroup]="this.editarDocenteFormGroup">
    <div class="row">
      <div class="col-3 text-left">
        <label class="labelForm text-sm" for="estadoRegistro">Estado de Registro</label>
      </div>
      <div class="col-9">
        <mat-form-field class="w-full">
          <mat-select formControlName='estadoRegistro' placeholder="Estado del docente">
            <mat-option *ngFor="let item of this.listaEstados"
              [value]="item.nombreEstado">{{item.nombreEstado}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-3 text-left">
        <label class="labelForm text-sm" for="codNivel">Agrupación</label>
      </div>
      <div class="col-9">
        <mat-form-field appearance="outline" class="example-full-width fullWidth">
          <mat-select placeholder="Nivel" formControlName="codNivel" name="codNivel"
            [selected]="this.obtenerSubsectores(null)">
            <mat-option *ngFor="let item of this.listaNiveles" [value]="item.codigo">
              {{item.codigo}} - {{item.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-3 text-left">
        <label class="labelForm text-sm" for="codSubsector">Asignatura</label>
      </div>
      <div class="col-9">
        <mat-form-field appearance="outline" class="example-full-width fullWidth">
          <mat-select placeholder="Subsector" formControlName="codSubsector" name="codSubsector">
            <mat-option *ngFor="let subsector of this.subsectores" [value]="subsector.codigo">
              {{subsector.codigo}} - {{subsector.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-3 text-left">
        <label class="labelForm text-smSinPadding" for="motivoNoInscripcion">Motivo no Inscripción</label>
      </div>
      <div class="col-9">
        <mat-form-field appearance="outline" class="example-full-width fullWidth">
          <textarea matInput style="height: 7.5vh;" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" type='text' placeholder='Motivo No Inscripción'
            formControlName='motivoNoInscripcion' name='motivoNoInscripcion'></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
</ng-template>



<!-- Modal suspender eximir -->
<div class="modal fade" id="modalInfoSuspenderEximir" tabindex="-1" aria-labelledby="modalInformacionDelDocenteLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="modalInformacionDelDocenteLabel">Información del Suspensión o Eximición</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div *ngIf="!docenteSuspenderEximir">Sin Datos</div>
        <div *ngIf="docenteSuspenderEximir" class="px-4">
          <table style="width:100%;" role="table" aria-label="tabla suspender eximir">
            <tr>
              <th class="" thInfo>Rut del Docente</th>
              <td>{{rutInfoSuspender}}</td>
            </tr>
            <tr>
              <th class="" thInfo>Nombre del Docente</th>
              <td>{{nombreInfoSuspender}}</td>
            </tr>
            <tr>
              <th class="thInfo">Acción</th>
              <td class="tdInfo">{{docenteSuspenderEximir.motivo}}</td>
            </tr>
            <tr>
              <th class="thInfo">Nombre de Motivo</th>
              <td>{{docenteSuspenderEximir.nombreMotivo}}</td>
            </tr>
            <tr>
              <th class="thInfo">Estado</th>
              <td>{{docenteSuspenderEximir.estado}}</td>
            </tr>
            <tr>
              <th class="thInfo">Fecha de creación</th>
              <td>{{docenteSuspenderEximir.fechaCreacion | date:'d/M/yy H:mm'}}</td>
            </tr>
            <tr>
              <th class="thInfo">Fecha de actualización</th>
              <td>{{docenteSuspenderEximir.fechaActualizacion | date:'d/M/yy H:mm'}}</td>
            </tr>
            <tr *ngIf="docenteSuspenderEximir.archivoSolicitud">
              <th>Archivo Solicitud</th>
              <td><button mat-raised-button (click)="getArchivoObligatorio(docenteSuspenderEximir.archivoSolicitud)"
                  color="primary">
                  Descargar</button></td>
            </tr>
            <tr *ngIf="docenteSuspenderEximir.archivoExtra">
              <th class="thInfo">Archivo Extra</th>
              <td><button mat-raised-button (click)="getArchivoObligatorio(docenteSuspenderEximir.archivoExtra)"
                  color="primary">
                  Descargar</button></td>
            </tr>
            <tr *ngIf="docenteSuspenderEximir.archivoConfirmacion">
              <th class="thInfo">Archivo Confirmación</th>
              <td><button mat-raised-button (click)="getArchivoObligatorio(docenteSuspenderEximir.archivoConfirmacion)"
                  color="primary">
                  Descargar</button></td>
            </tr>
            <tr *ngIf="docenteSuspenderEximir.archivoObligatorio">
              <th class="thInfo">Archivo Obligatorio</th>
              <td><button mat-raised-button (click)="getArchivoObligatorio(docenteSuspenderEximir.archivoObligatorio)"
                  color="primary">
                  Descargar</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- PDF Viewer -->

<div class="modal fade" id="modalPDF" tabindex="-1" aria-labelledby="modalPDF" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content" style=" height: 90vh;">
      <div class="modal-body" oncontextmenu="return false;">
        <button type="button" data-bs-dismiss="modal" aria-label="Close"
          class="rounded-md p-2 inline-flex text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
          <span class="sr-only">Close menu</span>
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <mat-progress-bar mode="indeterminate" *ngIf="isLoading" />
        <p class="placeholder-glow" *ngIf="isLoading">
          <span class="placeholder col-12" style="height:80vh !important"></span>
        </p>
        <iframe title="pdf" #iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" height="800"
          toolbar="0" width="100%"></iframe>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->

<div class="modal fade" id="modalInformacionDelDocente" tabindex="-1"
  aria-labelledby="modalInformacionDelDocenteLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable  modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div class="flex flex-col">
          <h1 class="modal-title fs-5" id="modalInformacionDelDocenteLabel">Detalles extendidos del docente <b>{{docenteSeleccionadoModal.rutDocente}}</b></h1>
          <div class="text-left text-md flex flex-row gap-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path d="M10 11V8l5 4-5 4v-3H1v-2h9zm-7.542 4h2.124A8.003 8.003 0 0 0 20 12 8 8 0 0 0 4.582 9H2.458C3.732 4.943 7.522 2 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10c-4.478 0-8.268-2.943-9.542-7z"/> </g> </svg>
            <p *ngIf="docenteSeleccionadoModal.ultimoLogin && docenteSeleccionadoModal.ultimoLogin.includes('-')">Últ. Ingreso: {{docenteSeleccionadoModal.ultimoLogin | date:'dd-MM-yyyy HH:mm:ss'}}</p>
            <p *ngIf="!docenteSeleccionadoModal.ultimoLogin">Últ Ingreso: No ha ingresado a la plataforma</p>
            <p *ngIf="docenteSeleccionadoModal.ultimoLogin && !docenteSeleccionadoModal.ultimoLogin.includes('-')">Últ. Ingreso: {{docenteSeleccionadoModal.ultimoLogin}} </p>
          </div>
        </div>
        <button type="button"  data-bs-dismiss="modal" aria-label="Close">✕</button>
      </div>
      <div class="modal-body" *ngIf="mostrarInfoDocente && acordeonesInfoDocente.length>0">

        <acordeon-tw
          [accordeonParent]="acordeonesInfoDocenteName"
          [acordeones]="acordeonesInfoDocente"></acordeon-tw>
      </div>
    </div>
  </div>
</div>

<ng-template #infoDatosPersonales
  let-personalDataSection="personalDataSection"
  let-docenteSeleccionadoModal="docenteSeleccionadoModal">
  <div class="" *ngIf="personalDataSection">
    <table style="width:100%;" role="table" aria-label="info datos personales">
      <tr *ngFor="let item of personalDataSection">
        <th>
          {{item.value}}
        </th>
        <td *ngIf="item.key != 'apellidos'" style="width:fit-content">{{docenteSeleccionadoModal[item.key]}}
        </td>
        <td *ngIf="item.key == 'apellidos'" style="width:fit-content">
          {{docenteSeleccionadoModal['primerApellido']}} {{docenteSeleccionadoModal['segundoApellido']}}
        </td>
      </tr>
    </table>
  </div>
</ng-template>


<ng-template #infoDatosEstablecimiento
  let-establishmentDataSection="establishmentDataSection"
  let-docenteSeleccionadoModal="docenteSeleccionadoModal">
  <table style="width:100%;" role="table" aria-label="tabla info establecimiento">
    <tr *ngFor="let item of establishmentDataSection">
      <th>
        {{item.value}}
      </th>
      <td class="break-words text-wrap">{{docenteSeleccionadoModal[item.key]}}</td>
    </tr>
  </table>
</ng-template>

<ng-template #infoDatosInscripcion
  let-inscriptionDataSection="inscriptionDataSection"
  let-docenteSeleccionadoModal="docenteSeleccionadoModal">
  <table style="width:100%;" role="table" aria-label="tabla info inscripción">
    <tr *ngFor="let item of inscriptionDataSection">
      <th>
        {{item.value}}
      </th>
      <td *ngIf="['codNivel','codSubsector'].includes(item.key)" class="flex flex-col gap-1 pb-2">
        <p *ngIf="item.key=='codNivel'" class="text-xs text-slate-700 cursor-zoom-in text-ellipsis" title="{{docenteSeleccionadoModal['nombreNivelPrevio']}}"> {{docenteSeleccionadoModal['nombreNivelPrevio']}} </p>
        <p *ngIf="item.key=='codSubsector'" class="text-xs text-slate-700 cursor-zoom-in text-ellipsis" title="{{docenteSeleccionadoModal['nombreSubsectorPrevio']}}"> {{docenteSeleccionadoModal['nombreSubsectorPrevio']}} </p>
        <p class="text-sm"> {{docenteSeleccionadoModal[item.key]}} </p>
      </td>
      <td *ngIf="!['codNivel','codSubsector'].includes(item.key)" class="pb-2">
        <p *ngIf="item.value.includes('Fecha')">{{docenteSeleccionadoModal[item.key] | date:'dd-MM-yyyy HH:mm:ss' }}</p>
        <p *ngIf="!item.value.includes('Fecha')" class="text-wrap break-words">{{docenteSeleccionadoModal[item.key]}}</p>
      </td>

    </tr>
  </table>
</ng-template>


<ng-template #infoValidacion
  let-validationDataSection = "validationDataSection"
  let-esFecha = "esFecha"
  let-docenteSeleccionadoModal = "docenteSeleccionadoModal"
  >
  <div class="border-2 border-slate-600 rounded-xl p-4 ">
    <table style="width:100%;" role="table" aria-label="info tabla validacion" >
      <tr *ngFor="let item of validationDataSection" >
        <th #header class="w-4/6">
          <p *ngIf="!['beneficios', 'fechaValidacion'].includes(item.key)" class="font-bold">{{item.value}}</p>
          <div *ngIf=" item.key == 'fechaValidacion'" class="flex flex-row gap-1 font-bold">
            Fecha de <p *ngIf="docenteSeleccionadoModal['flagValidacion'] != false" class="font-bold ">Validacion</p>
            <del *ngIf="docenteSeleccionadoModal['flagValidacion'] == false" class="font-bold text-gray-400">Validación</del>
            <p *ngIf="docenteSeleccionadoModal['flagValidacion'] == false" class="font-bold ">Actualización </p></div>
          <div *ngIf="item.key=='beneficios'" class="flex flex-row gap-1 font-bold"> Beneficios <div class="flex flex-row font-bold">(<div class="text-green-500 font-bold pr-1">Acogidos</div><div class="text-slate-600 font-bold"> / No Acogidos</div>)</div></div>
        </th>

        <td #iconos class="px-4">
          <div  *ngIf="item.key == 'estadoValidacion'" >
              <svg *ngIf="docenteSeleccionadoModal['flagValidacion'] == true && docenteSeleccionadoModal[item.key] && ['Aceptado', 'Validado'].includes(docenteSeleccionadoModal[item.key])" width="16" height="16" style="color: rgb(51, 215, 138);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" fill="#33d78a"></path></svg>
              <svg *ngIf="!docenteSeleccionadoModal['flagValidacion']|| docenteSeleccionadoModal[item.key] && ['No Validado', 'Rechazado'].includes(docenteSeleccionadoModal[item.key] ) " width="16" height="16" style="color: red;" width="20" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z" fill="#c43b0e"></path> </svg>
          </div>

          <div *ngIf="['nivelPrevio','subsectorPrevio','nivelSolicitado', 'subsectorSolicitado'].includes(item.key)"  >
              <svg *ngIf="item.value.includes('desde Inscripción')" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/> <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/> </svg>
              <svg *ngIf="item.value.includes('Solicitada')" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/> </svg>
          </div>
          <div  *ngIf="item.key == 'estadoConfirmacionSubsector'"  >
              <svg *ngIf="docenteSeleccionadoModal['flagValidacion'] && docenteSeleccionadoModal[item.key] && ['Aceptado', 'Validado'].includes(docenteSeleccionadoModal[item.key])" width="16" height="16" style="color: rgb(51, 215, 138);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" fill="#33d78a"></path></svg>
              <svg *ngIf="!docenteSeleccionadoModal['flagValidacion'] || docenteSeleccionadoModal[item.key] && ['No Validado', 'Rechazado'].includes(docenteSeleccionadoModal[item.key] ) " width="16" height="16" style="color: red;" width="20" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z" fill="#c43b0e"></path> </svg>
            </div>
        </td>


        <div #celdaDatosValidacion class="pb-1">
          <div  *ngIf="item.key == 'estadoValidacion'">
            <td>
              <p class="text-md font-bold">{{ !docenteSeleccionadoModal['flagValidacion']  ? "No Validado" : "Validado"}}</p>
              <p *ngIf="historialValidacion" class="text-sm">
                Datos Actualizados
              </p>
              <p *ngIf="!historialValidacion" class="text-sm">
                Docente no actualizó sus datos
              </p>
            </td>
          </div>

          <div  *ngIf="item.key == 'fechaValidacion'" class="pb-2" >
            <td *ngIf="docenteSeleccionadoModal[item.key]">
              {{formatearHoraChile(docenteSeleccionadoModal[item.key]) | date:'dd-MM-yyyy HH:mm:ss' }} {{ docenteSeleccionadoModal['flagValidacion'] == null ? "Docente no ha validado": ""}}
            </td>
          </div>

          <div *ngIf="item.key == 'beneficios'" class="flex flex-row gap-2" >
            <td class="flex flex-col border-0 pb-2 " >
              <p *ngIf="docenteSeleccionadoModal['beneficio19n'] == null && docenteSeleccionadoModal['aplazamiento'] == null &&
              docenteSeleccionadoModal['tramos'] == null && docenteSeleccionadoModal['codDebeRendir'] != 1"
              class="text-left">Sin beneficios</p>

              <span *ngIf=" docenteSeleccionadoModal['beneficio19n'] != null"
                class="badge rounded-pill me-2 mb-1" [ngClass]="{'bg-green-500' : docenteSeleccionadoModal['beneficio19n'] == true,
                            'bg-slate-600' : docenteSeleccionadoModal['beneficio19n'] == false}">19Ñ
              </span>
              <span *ngIf=" docenteSeleccionadoModal['aplazamiento'] != null"
                class="badge rounded-pill me-2 mb-1" [ngClass]="{'bg-green-500' : docenteSeleccionadoModal['aplazamiento'] == true,
                            'bg-slate-600' : docenteSeleccionadoModal['aplazamiento'] == false}">Aplazamiento

              </span>
              <span *ngIf=" docenteSeleccionadoModal['tramos'] != null"
                class="badge rounded-pill me-2 mb-1" [ngClass]="{'bg-green-500' : docenteSeleccionadoModal['tramos'] == true,
                            'bg-slate-600' : docenteSeleccionadoModal['tramos'] == false}">Tramos Voluntarios
              </span>

              <span *ngIf=" docenteSeleccionadoModal['codDebeRendir'] == 1 && docenteSeleccionadoModal['tramos'] == null"
                class="badge rounded-pill me-2 mb-1 text-slate-900 bg-slate-200">Tramos Volunt. Disponible
              </span>
            </td>
          </div>

          <div  *ngIf="item.key == 'agnoPortafolioCorregido'" class="flex flex-row gap-2"  >
            <td class="text-sm">{{docenteSeleccionadoModal[item.key] ? docenteSeleccionadoModal[item.key] : 'Sin portafolios corregidos'}}</td>
          </div>

          <div  *ngIf="item.key == 'solicitaCambioSubs'" class="flex flex-row gap-2" >
            <td class="flex flex-col gap-1 border-0 pb-2">
              <p class="text-sm">{{
                (docenteSeleccionadoModal['estadoValidacion'] | lowercase) == 'no validado' ? 'No aplica' :
                docenteSeleccionadoModal[item.key] ? 'Sí' : 'No'}}</p>
              <p class="text-sm font-bold" *ngIf="docenteSeleccionadoModal['fechaInicioSolicitud']"> {{ formatearHoraChile(docenteSeleccionadoModal['fechaInicioSolicitud']) | date:'dd-MM-yyyy HH:mm:ss'}} </p>
            </td>

          </div>

          <div *ngIf="['nivelPrevio','subsectorPrevio','nivelSolicitado', 'subsectorSolicitado'].includes(item.key)" >
            <td  class="flex flex-col pb-2 gap-1 border-0">
              <p *ngIf="item.key=='nivelPrevio'" class="text-xs text-slate-700 cursor-zoom-in text-ellipsis" title="{{docenteSeleccionadoModal['nombreNivelPrevio']}}"> {{docenteSeleccionadoModal['nombreNivelPrevio']}} </p>
              <p *ngIf="item.key=='subsectorPrevio'" class="text-xs text-slate-700 cursor-zoom-in text-ellipsis" title="{{docenteSeleccionadoModal['nombreSubsectorPrevio']}}"> {{docenteSeleccionadoModal['nombreSubsectorPrevio']}} </p>
              <p *ngIf="item.key=='nivelSolicitado'" class="text-xs text-slate-700 cursor-zoom-in text-ellipsis" title="{{docenteSeleccionadoModal['nombreNivelSolicitado']}}"> {{docenteSeleccionadoModal['nombreNivelSolicitado']}} </p>
              <p *ngIf="item.key=='subsectorSolicitado'" class="text-xs text-slate-700 cursor-zoom-in text-ellipsis" title="{{docenteSeleccionadoModal['nombreSubsectorSolicitado']}}"> {{docenteSeleccionadoModal['nombreSubsectorSolicitado']}} </p>
              <p class="text-sm">{{docenteSeleccionadoModal[item.key]}}</p>
            </td>
          </div>

          <div  *ngIf="item.key == 'estadoConfirmacionSubsector'" >
            <td class="flex flex-col gap-1 border-0 pb-2">
              <p class="text-sm">{{docenteSeleccionadoModal['solicitaCambioSubs'] == true ? docenteSeleccionadoModal[item.key] : ''}}</p>
              <p class="text-sm font-bold" *ngIf="docenteSeleccionadoModal['fechaUltEstado']">{{formatearHoraChile(docenteSeleccionadoModal['fechaUltEstado']) | date:'dd-MM-yyyy HH:mm:ss' }}</p>
            </td>

          </div>

        </div>
      </tr>
    </table>
  </div>
</ng-template>

<ng-template #infoEximirSuspender
  let-exemptSuspendDataSection="exemptSuspendDataSection"
  let-docenteSeleccionadoModal="docenteSeleccionadoModal">
  <table style="width:100%;" role="table" aria-label="tabla info eximir suspender">
    <tr *ngFor="let item of exemptSuspendDataSection; let i=index" class="pb-4 border-0">
      <th>
        {{item.value}}
      </th>
      <td *ngIf="i<=1" class="text-md">{{docenteSeleccionadoModal[item.key] | date:'dd-MM-yyyy HH:mm:ss'}}</td>
      <td *ngIf="i>1" class="break-words text-wrap text-md">{{docenteSeleccionadoModal[item.key]}}</td>
    </tr>
  </table>
</ng-template>

<ng-template #infoHistorial
  let-historialValidacion="historialValidacion">
  <ficha-docente-timeline-validacion *ngIf="historialValidacion" [historialValidacion]="historialValidacion"></ficha-docente-timeline-validacion>
</ng-template>

