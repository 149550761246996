import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RegistroApertura } from "../models/registroApertura";
import { map, Observable } from "rxjs";
import { Constants } from "src/app/base/constants/constants";
import { FiltroApertura } from "../models/filtroApertura";
import { SD } from "../models/sd";
import { CajaBulto } from "../models/CajaBulto";
import { NuevaCaja } from "../models/nuevaCaja";
import { PaginationOptions } from "src/app/registros/models/paginationOptions";

@Injectable({
  providedIn: 'root'
})
export class AperturaCajaService {
  constructor(
    private http: HttpClient
  ) { }

  getCajas( filtros: FiltroApertura, options: PaginationOptions): Observable<any | RegistroApertura[]>  {
    let url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/`;
    if (options) url += `?page=${options.page}&size=${options.size}`
    const params = this.obtenerParams(filtros);
    return this.http.get(url, {params: params});
  }

  obtenerParams(filtro: FiltroApertura) {
    let params = new HttpParams();

    if (null != filtro.folio?.trim()) {
      params = params.set('folioCaja', filtro.folio.trim().toUpperCase());
    }

    if (null != filtro.bulto?.trim()) {
      params = params.set('folioBulto', filtro.bulto.trim().toUpperCase());
    }
    if (null != filtro.estado) {
      params = params.set('estado', filtro.estado);
    }
    if (null != filtro.fecha) {
      const fecha = new Date(filtro.fecha).toISOString();
      params = params.set('fecha', fecha);
    }
    if (null != filtro.operador?.trim()) {
      params = params.set('operador', filtro.operador.trim());
    }
    if (null != filtro.rutSostenedor) {
      params = params.set('rutSostenedor', filtro.rutSostenedor);
    }
    if (null != filtro.rutDocente) {
      params = params.set("rutDocente", filtro.rutDocente);
    }
    if (null != filtro.qr?.trim()) {
      params = params.set("qr", filtro.qr.trim().toUpperCase());
    }
    return params;
  }

  folioCajaDisponible(folio: string) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/disponible/${folio}`;
    return this.http.get(url);
  }

  folioBultoDisponible(folio: string) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/disponible/bulto/${folio}`;
    return this.http.get(url);
  }

  getIDBulto(folio: string): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/bulto/${folio}`;
    return this.http.get(url);
  }

  obtenerBultos(): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/bultos`;
    return this.http.get(url);
  }

  obtenerDiferenciaSDdeBulto(folio: string): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/diferencia/bulto/${folio}`;
    return this.http.get(url);
  }

  crearCaja(caja: NuevaCaja) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/`;
    return this.http.post(url, caja);
  }

  editarCaja(idCaja: number, idBulto: number, manifiesto: boolean) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/`;
    return this.http.put(url, {
      idCaja: idCaja,
      idBulto: idBulto,
      manifiesto: manifiesto
    });
  }

  borrarCaja(idCaja: number, folioCaja: string) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/id/${idCaja}/folio/${folioCaja}`;
    return this.http.delete(url);
  }

  consultarQR(qr: string, rut: number = null): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/sd`;
    return this.http.get(url, {params: {qr: qr, rutDocente:rut == null ? 0 : rut}});
  }

  insertarQR(sd: SD) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/sd`;
    return this.http.post(url, sd);
  }

  borrarSD(qr: string, idCaja: number, idBulto: number): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/sd/${qr}/${idCaja}/${idBulto}`;
    return this.http.delete(url);
  }

  bultosDeCajas(idCaja: number, idBulto: number): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/caja/${idCaja}/bulto/${idBulto}`;
    return this.http.get(url);
  }

  editarSD(sd: SD): Observable<any> {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/sd/`;
    return this.http.put(url, sd);
  }

  listarSD (caja: string) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/sd/caja/${caja}`;
    return this.http.get(url);
  }

  validaRut(rut: number) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/registro/docente/rut/${rut}`;
    return this.http.get(url);
  }

  downloadBlob(filename: string, blob: any) {
    const dwldLink = document.createElement('a');
    const url = URL.createObjectURL(blob);
    const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename);
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  descargarCajas(url: string, filtro: FiltroApertura): Observable<any> {
    let params = this.obtenerParams(filtro);
    return this.http.get(url, { responseType: 'blob', params: params }).pipe(
      map(response => response)
    );
  }

  obtenerBulto(folioBulto: string) {
    let params = new HttpParams();
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/caja/bulto/${folioBulto}`;
    return this.http.get(url);
  }

  obtenerDiferenciaSDBulto(caja: RegistroApertura) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/conteo/sd/caja/${caja.idCaja}/bulto/${caja.idBulto}`;
    return this.http.get(url);
  }

  sdRestantes(idCaja: number) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/contar/sd/caja/${idCaja}`;
    return this.http.get(url);
  }

  agregarCajaBulto(bultoForm: CajaBulto) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/cajaBulto`;
    return this.http.post(url, bultoForm);
  }

  actualizarConteos(folioCaja: string) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/conteos/caja/${folioCaja}`;
    return this.http.get(url);
  }

  sellarCaja(id: number){
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/sellarCaja`;
    return this.http.put(url, id);

  }

  abrirCaja(id: number){
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/abrirCaja`;
    return this.http.put(url, id);

  }

  cantidadSDCaja(id: number): Observable<any>{
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/cantidadSDCaja`;
    let params = new HttpParams();
    params = params.set('idCaja',id)
    return this.http.get(url, {params: params});

  }

  obtenerCajasConBultoAEliminar(idBulto: number) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/cajas/bulto/${idBulto}`;
    return this.http.get(url);
  }

  eliminarBulto(idCaja:number, idBulto: number, bultosEnCaja: CajaBulto[], param: {caso:number, actual: boolean}): Observable<any> {
    const sgteBulto = bultosEnCaja.length>1 && param.actual ? bultosEnCaja[1].idBulto: -1;
    const nulificaCapacidad = [0,1].includes(param.caso) ? 1 : 0;
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/caja/${idCaja}/bulto/${idBulto}/caso/${nulificaCapacidad}/actual/${param.actual ? 1: 0}/pop/${sgteBulto}`;
    return this.http.delete(url);
  }

  cambiarBultoActual(item: CajaBulto) {
    const url = `${Constants.URL_SERVICE.BASE_GRABACIONES}/apertura/cambio-bulto-actual`;
    return this.http.put(url, item);
  }
}
