<mat-card  class="p-4 min-h-screen" style="box-shadow: none !important; overflow-x: hidden;" data-te-smooth-scroll-init>
  <div class="flex flex-col gap-4 px-2 h-[13vh]">
    <p class="text-blue-700 text-2xl font-bold border-4 w-fit p-2 border-b-red-600 border-r-red-600 border-t-blue-600 border-l-blue-600 rounded-tl-2xl rounded-br-2xl">Grabaciones 2024</p>
    <div class="inline-flex gap-2">
      <button *ngIf="modoSD" matTooltip="Regresar al menú de Apertura de Cajas" (click)="volverMenuPpal()">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 " viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
      </button>

      <div class="inline-flex gap-2">
        <p class="text-blue-950 text-2xl font-bold">Apertura de Cajas</p>
        <p matTooltip="Prepárese el café mientras preparamos los datos." *ngIf="isLoading" class="rounded-2xl cursor-wait bg-blue-700 pt-1 px-2 font-bold text-sm text-white animate-pulse">CARGANDO</p>
        <p matTooltip="Estamos presentando intermitencias en nuestros servidores" *ngIf="!isLoading && (!listaBultos)" class="rounded-2xl cursor-not-allowed bg-red-600 pt-1 px-2 font-bold text-sm text-white">OFFLINE</p>
        <p matTooltip="Servicio funcionando sin problemas." *ngIf="!isLoading && listaBultos" class="rounded-2xl bg-green-500 pt-1 px-2 font-bold text-sm text-white">ONLINE</p>
      </div>
    </div>
  </div>
  <div [hidden]="modoSD" class="panel-body m-2 p-2 flex flex-col gap-4"  #FILTRO >
      <div class="filtros2 form-group row py-2 px-4 m-2 rounded-2xl border-l-[12px] border-2  border-l-blue-500 shadow-4">
        <div class="flex flex-row gap-1 py-2">
          <mat-icon>filter_list</mat-icon>
          <p class="text-lg font-bold">Filtros</p>
        </div>
        <div #inputs class="row" aria-label="sección de inputs">
          <div class="col-lg-3 lg:col-xl-2 col-md-6 col-sm-6 col-xs-12 col-xl-2 ">
            <mat-form-field class="w-full">
              <mat-label>Folio de Caja</mat-label>
              <input type="text" matInput
                placeholder="Formato Ej: BB001" [(ngModel)]="filtros.folio" name="folio" />
            </mat-form-field>
          </div>

          <div class="col-lg-3 lg:col-xl-2 col-md-6 col-sm-6 col-xs-12 col-xl-2 ">
            <mat-form-field class="w-full">
              <mat-label>Código de Bulto</mat-label>
              <input type="text" matInput
                placeholder="Formato Ej: ZZ00001" [(ngModel)]="filtros.bulto" name="bulto" />
            </mat-form-field>
          </div>

          <div class="col-lg-3 lg:col-xl-2 col-md-6 col-sm-6 col-xs-12 col-xl-2 ">
            <mat-form-field class="w-full">
              <mat-label>Estado</mat-label>
              <mat-select placeholder="Seleccione un Estado" [(ngModel)]="filtros.estado"  name="item" >
                <mat-option *ngFor="let item of estados" [value]="item.id">
                  {{item.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-lg-3 lg:col-xl-2 col-md-6 col-sm-6 col-xs-12 col-xl-2 ">
            <mat-form-field class="w-full">
              <mat-label>Fecha</mat-label>
              <input matInput [matDatepicker]="picker" [max]="today" [min]="minDate" (dateChange)="onDateChange($event)"
                placeholder="Fecha" [(ngModel)]="filtros.fecha">
              <mat-hint>DD/MM/AAAA</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker" class=""></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-lg-3 lg:col-xl-2 col-md-6 col-sm-6 col-xs-12 col-xl-2 ">
            <mat-form-field class="w-full">
              <mat-label>Sostenedor</mat-label>
              <input type="text" matInput
                placeholder="Formato Ej: 12345678" [(ngModel)]="filtros.rutSostenedor" name="sostenedor" />
            </mat-form-field>
          </div>

          <div class="col-lg-3 lg:col-xl-2 col-md-6 col-sm-6 col-xs-12 col-xl-2 ">
            <mat-form-field class="w-full">
              <mat-label>Operador</mat-label>
              <input type="text" matInput
                placeholder="usuario@iie.cl" [(ngModel)]="filtros.operador" name="operador" />
            </mat-form-field>
          </div>

          <div class="col-lg-3 lg:col-xl-2 col-md-6 col-sm-6 col-xs-12 col-xl-2 ">
            <mat-form-field class="w-full">
              <mat-label>Rut Docente</mat-label>
              <input type="number" matInput
                placeholder="12345678" [(ngModel)]="filtros.rutDocente" name="rutDocente"/>
            </mat-form-field>
          </div>

          <div class="col-lg-3 lg:col-xl-2 col-md-6 col-sm-6 col-xs-12 col-xl-2 ">
            <mat-form-field class="w-full">
              <mat-label>Código QR</mat-label>
              <input type="text" matInput
                placeholder="99999-AA00001" [(ngModel)]="filtros.qr" name="qr"/>
            </mat-form-field>
          </div>

        </div>
        <div class="d-flex" aria-describedby="footer filtro">
          <div aria-labelledby="botones inf. izq" class="float-left d-flex  align-self-start ">
            <mat-card-actions class="d-flex row">
              <button
                type="button"
                data-te-ripple-init
                (click)="filtrar()"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-primary  pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg width="24" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M21 12V10C21 7.23858 18.7614 5 16 5H8C5.23858 5 3 7.23858 3 10V10C3 12.7614 5.23858 15 8 15H12" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.1241 19.1185C20.6654 18.5758 21 17.827 21 17C21 15.3431 19.6569 14 18 14C16.3431 14 15 15.3431 15 17C15 18.6569 16.3431 20 18 20C18.8299 20 19.581 19.663 20.1241 19.1185ZM20.1241 19.1185L22 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                <p class="pr-1"></p> Filtrar
              </button>
              <button
                type="button"
                data-te-ripple-init
                (click)="limpiar()"
                data-te-ripple-color="light"
                class="d-flex w-28  mx-2 justify-items-center justify-center text-center items-center rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" > <rect x="2" y="6" width="20" height="12" rx="2" /> <path d="M12 12h.01" /> <path d="M17 12h.01" /> <path d="M7 12h.01" /> </svg>
                <p class="pr-1"></p> Limpiar
              </button>


            </mat-card-actions>
            <mat-card-actions class="d-flex row">
              <div class="float-right col d-flex justify-content-start">
                <button
                    matTooltip="Consulta y descarga esta tabla de Fichas"
                    *ngIf="length>0"
                    type="button"
                    data-te-ripple-init
                    (click)="descargar()"
                    data-te-ripple-color="light"
                    class="d-flex w-fit px-2 mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                    <fa-icon *ngIf="estaDescargandoExcel" class="pr-2" [icon]="faSpinner" disabled animation="spin"></fa-icon>
                    <svg *ngIf="!estaDescargandoExcel" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="pr-2" viewBox="0 0 16 16"> <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/> <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/> </svg>
                    <div *ngIf="!estaDescargandoExcel" class="text-[14px]">Descargar</div>
                    <div *ngIf="estaDescargandoExcel" class="text-[14px]">Descargando</div>
                </button>
              </div>
            </mat-card-actions>

            <mat-card-actions class="d-flex row">
              <div class="float-right col d-flex justify-content-end">
              <button *ngIf="listaBultos" data-bs-toggle="modal" data-bs-target="#crearCajaModal" (click)="cleanModalCaja()"
                class="d-flex w-fit px-2 mx-2 justify-items-center justify-center text-center items-center my-2 rounded-xl bg-slate-50 pb-2 pt-2.5 text-[14px] font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-slate-300 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]  focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]">
                <i class="fas fa-plus mr-2"></i>
                CREAR
              </button>
              </div>
            </mat-card-actions>
          </div>
        </div>
      </div>

      <lootie-animation [ngClass]="{ 'z-20 opacity-0 h-0': !isLoading, 'z-0': esconderAnimacion }" class="z-10 fade-out" [file]="archivoAnimacionPrincipal"></lootie-animation>

    <div [hidden]="isLoading" [ngClass]="{'z-0':isLoading, 'z-10': esconderAnimacion}"
      class=" overflow-x-scroll rounded-2xl border border-gray-200 m-2 p-2" aria-label="sección tabla" >
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading" />
      <table class="w-full table-auto table-hover rounded-2xl table bg-gray-50" role="table">
        <thead class="bg-blue-700 ">
          <tr>
            <th scope="col" class="rounded-tl-2xl pl-2 pr-4 py-3 text-left text-blue-100 text-sm font-black w-14 items-baseline" ></th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black ">GESTOR DE SD</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black min-w-[25vw] xl:min-w-[35vw]">BULTOS ASOCIADOS</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >FECHA CREACIÓN</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >OPERADOR RESPONSABLE</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >ESTADO DE CAJA</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >ACCIONES</th>
            <th scope="col" class=" pr-4 py-3 text-left text-blue-100 text-sm font-black "  >SELLADO</th>
            <th scope="col" class=" py-3 text-left text-blue-100 text-sm font-black w-[80px] ">

            </th>
            <th scope="col" class="rounded-tr-2xl pl-2 pr-4 py-3 text-right text-blue-100 w-14" ></th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <tr class="min-w-full" *ngFor="let caja of response">
            <td class="negrita-tabla"></td>
            <td class="negrita-tabla">
              <div class="flex flex-col gap-1">
                <button matTooltip="Abrir Gestor de Tarjetas SD de esta caja"
                (click)="seleccionarCaja(caja)"
                class="flex flex-col flex justify-center items-center text-center rounded-2xl border-2 border-blue-600 shadow-2 shadow-blue-600 px-2 py-1 w-fit hover:animate-pulse hover:shadow-4-strong hover:shadow-blue-500 hover:font-bold" >
                  <div class="inline-flex gap-2 items-start">
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"/><polyline points="184 48 224 48 224 88" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><polyline points="72 208 32 208 32 168" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><polyline points="224 168 224 208 184 208" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><polyline points="32 88 32 48 72 48" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><line x1="80" y1="88" x2="80" y2="168" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><line x1="176" y1="88" x2="176" y2="168" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><line x1="144" y1="88" x2="144" y2="168" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/><line x1="112" y1="88" x2="112" y2="168" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"/></svg>
                    {{caja.folio}}
                  </div>
                  <div class="text-xs inline-flex gap-2">
                    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-sd-card" viewBox="0 0 16 16"> <path d="M6.25 3.5a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0v-2zm2 0a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0v-2zm2 0a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0v-2zm2 0a.75.75 0 0 0-1.5 0v2a.75.75 0 0 0 1.5 0v-2z"/> <path fill-rule="evenodd" d="M5.914 0H12.5A1.5 1.5 0 0 1 14 1.5v13a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5V3.914c0-.398.158-.78.44-1.06L4.853.439A1.5 1.5 0 0 1 5.914 0zM13 1.5a.5.5 0 0 0-.5-.5H5.914a.5.5 0 0 0-.353.146L3.146 3.561A.5.5 0 0 0 3 3.914V14.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-13z"/> </svg>
                    {{caja.conteoSD}} / {{caja.capacidad}}
                  </div>
                </button>
              </div>
            </td>
            <td>
              <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4  xs:gap-1 md:gap-2">
                <div *ngFor="let bulto of jsonBultos(caja.bultos)" class="border-2 p-1  w-fit border-slate-300 ">
                  <p class="text-xs px-1">{{bulto.sostenedor}}</p>
                  <div
                    class="border-2 p-1 w-fit border-gray-400 rounded-lg flex flex-col gap-0">
                    <p> {{bulto.folioBulto}} </p>
                  </div>
                  <p class="text-xs" [ngClass]="{'font-bold text-green-500': bulto.tarjetas == bulto.conteoBulto}">
                    {{bulto.tarjetas}} SD</p>
                </div>
              </div>
              <div *ngIf="jsonBultos(caja.bultos) == null" class="pt-2 mt-1">
                Caja vacía.
              </div>
            </td>

            <td class="negrita-tabla">
              <div class="flex flex-col gap-1">
                <div class="inline-flex gap-2 items-start " >
                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16"> <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/> <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/> </svg>
                  {{caja.fecha}}
                </div>
              </div>
            </td>
            <td class="negrita-tabla">
              <div class="flex flex-col gap-1">
                <div class="inline-flex gap-2 items-start " >
                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path d="M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm9.446 9.032l1.504 1.504-1.414 1.414-1.504-1.504a4 4 0 1 1 1.414-1.414zM18 20a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/> </g> </svg>
                  <!-- {{caja.operador}} -->
                   {{caja.operador}}
                </div>
              </div>
            </td>
            <td class="negrita-tabla">
              <div class="flex flex-col gap-1">
                <div class="inline-flex gap-2 items-start " >
                  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-box-seam" viewBox="0 0 16 16"> <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"/> </svg>
                  {{estadoCaja(caja.estado)}}
                </div>
              </div>
            </td>
            <td>
              <button #abrirModalCaja hidden data-bs-toggle="modal" data-bs-target="#crearCajaModal"></button>
              <div class="inline-flex rounded-full overflow-hidden shadow-4 ">
                <button
                [disabled]="caja.estado == 1"
                (click)="abrirModalEdicionCaja(caja)"
                matTooltip="Edita el manifiesto de esta caja"
                [ngClass]="{'hover:bg-gray-500 focus:ring-gray-500': caja.estado === 1}"
                class=" text-gray-700 px-4 py-2 text-sm font-medium hover:bg-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                  <i class="fas fa-edit mr-2"></i>
                  EDITAR
                </button>
                <button
                [disabled]="caja.estado == 1"
                [ngClass]="{'hover:bg-gray-500 focus:ring-gray-500': caja.estado === 1}"
                class="text-gray-700 px-4 py-2 text-sm font-medium hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  (click)="eliminar(caja)">
                  <i class="fas fa-trash-alt mr-2"></i>
                  BORRAR
                </button>
              </div>
              </td>
              <td>
                <div class="inline-flex rounded-full overflow-hidden shadow-4 ">
                  <div *ngIf="caja.estado == 0">
                    <button
                    (click)="sellar(caja.idCaja,caja.folio)"
                    class=" text-gray-700 px-4 py-2 text-sm font-medium hover:bg-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    <i class="fa-solid fa-box-archive"></i>
                      SELLAR
                    </button>
                  </div>
                <div *ngIf="caja.estado == 1">
                  <button
                    (click)="abrirCaja(caja.idCaja)"
                    class="text-gray-700 px-4 py-2 text-sm font-medium hover:bg-blue-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
                    <i class="fa-solid fa-boxes-packing"></i>
                    REABRIR
                  </button>
                </div>
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <mat-paginator #paginator [pageSizeOptions]="[10, 50, 100]" [showFirstLastButtons]="true" [length]="length"
      [pageIndex]="pageIndex" (page)="pageChanged($event)" aria-label="paginador de fichas">
    </mat-paginator>

  </div>

  <insertar-sd class="max-h-[85vh]" *ngIf="modoSD && cajaEnOperacion" [modoSD]="modoSD" [caja]="cajaEnOperacion"></insertar-sd>
</mat-card>

<div class="modal fade" id="crearCajaModal" tabindex="-1" aria-labelledby="crearCajaLabel" aria-hidden="false">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header bg-blue-800 text-white">
        <h5 *ngIf="!modoEdicionCaja" class="modal-title" id="crearCajaLabel">Crear Caja</h5>
        <h5 *ngIf="modoEdicionCaja" class="modal-title" id="crearCajaLabel">Editar Caja</h5>
        <button type="button" class="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body p-6 mx-4">
        <!-- Formulario dentro del modal -->
        <form [formGroup]="bultoInicialForm">
          <div class="mb-5">
            <label for="codigoCaja" class="form-label font-semibold text-gray-700">Folio de la caja</label>
            <input
              type="text"
              id="codigoCaja"
              class="form-control focus:ring focus:ring-blue-500 focus:border-blue-500"
              formControlName="codigoCaja"
              placeholder="Formato Ej: BB001"
              [readOnly]="bloquearCantidadSD"
              [ngClass]="{'focus:bg-gray-300 bg-gray-300 cursor-not-allowed':bloquearCantidadSD}"
            />
            <!-- Mensaje de error -->
            <div *ngIf="bultoInicialForm.get('codigoCaja').invalid" class="text-red-500 text-sm mt-1">
              Este folio debe seguir el formato BB000.
            </div>
            <div *ngIf="!modoEdicionCaja && bultoInicialForm.get('codigoCaja').valid && !folioCajaLibre" class="text-red-500 text-sm mt-1">
              Este folio de caja ya se encuentra registrado.
            </div>
          </div>

          <div #primerBulto *ngIf="modoEdicionCaja || folioCajaLibre && bultoInicialForm.get('codigoCaja').valid"
            class="border-2 bg-slate-100 border-blue-600 p-4 rounded-2xl">
            <div #bannerBulto class="flex flex-row gap-2 items-center mb-4">
              <svg class="w-14 h-14 fill-slate-200 float-center rounded-full bg-slate-600 py-2 pl-2 pr-3" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24"><path d="M22,16H19.82A3,3,0,0,0,20,15V10a3,3,0,0,0-3-3H11a3,3,0,0,0-3,3v5a3,3,0,0,0,.18,1H7a1,1,0,0,1-1-1V5A3,3,0,0,0,3,2H2A1,1,0,0,0,2,4H3A1,1,0,0,1,4,5V15a3,3,0,0,0,2.22,2.88,3,3,0,1,0,5.6.12h3.36a3,3,0,1,0,5.64,0H22a1,1,0,0,0,0-2ZM9,20a1,1,0,1,1,1-1A1,1,0,0,1,9,20Zm2-4a1,1,0,0,1-1-1V10a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v5a1,1,0,0,1-1,1Zm7,4a1,1,0,1,1,1-1A1,1,0,0,1,18,20Z"/></svg>
              <p class="text-xl font-bold">Ingreso de Bulto inicial</p>
            </div>
            <div #seleccionarBulto class="mb-5">
              <label for="codigoBulto" class="form-label font-semibold text-gray-700">Folio del Bulto</label>
              <lista-autocompletable
              *ngIf="listaBultos && !modoEdicionCaja"
              [control]="bultoControl"
              [options]="listaBultos"
              placeholder="Folio de Bulto, formato ej: ZZ00001"
              ></lista-autocompletable>
              <input
                *ngIf="modoEdicionCaja"
                type="text"
                id="codigoBulto"
                class="form-control focus:ring focus:ring-blue-500 focus:border-blue-500 focus:bg-gray-300 bg-gray-300 cursor-not-allowed"
                formControlName="codigoBulto"
                min="0"
                readonly
              />
            </div>

            <div #seleccionarManifiesto class="mb-5">
              <input
                class="me-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-black/25 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-white after:shadow-switch-2 after:transition-[background-color_0.2s,transform_0.2s] after:content-['']
                 checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ms-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-switch-1 checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-switch-3 focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ms-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-switch-3 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                type="checkbox"
                formControlName="manifiesto"
                role="switch"
                id="flexSwitchCheckDefault" />
              <label
                class="inline-block ps-[0.15rem] hover:cursor-pointer"
                for="flexSwitchCheckDefault"
                >Registra Manifiesto</label
              >
            </div>

            <div #ingresarSDdeBulto *ngIf="listaBultos?.includes(bultoInicialForm.value.codigoBulto)" class="mb-5">
              <label *ngIf="!bloquearCantidadSD" for="cantidadSD" class="form-label font-semibold text-gray-700">Defina la capacidad inicial del bulto</label>
              <label *ngIf="bloquearCantidadSD" for="cantidadSD" class="form-label font-semibold text-gray-700">Cantidad de SD restantes</label>
              <input
                type="number"
                id="cantidadSD"
                class="form-control focus:ring focus:ring-blue-500 focus:border-blue-500"
                formControlName="cantidadSD"
                placeholder="Ingresa la cantidad"
                min="0"
                [readOnly]="bloquearCantidadSD"
                [ngClass]="{'focus:bg-gray-300 bg-gray-300 cursor-not-allowed':bloquearCantidadSD}"
              />
              <p *ngIf="bultoInicialForm.value.cantidadSD >0"> Conteo correcto.</p>
              <div *ngIf="bultoInicialForm.value.cantidadSD == 0"> 
                <p *ngIf="bloquearCantidadSD">Bulto registrado y vaciado. Escoja otro bulto</p>
                <p *ngIf="!bloquearCantidadSD">No se puede iniciar la capacidad de este bulto con cero.</p>
              </div>
              <!-- Mensaje de error -->
              <div *ngIf="bultoInicialForm.get('cantidadSD').invalid && bultoInicialForm.get('cantidadSD').touched" class="text-red-500 text-sm mt-1">
                Debe ingresar un número entero positivo.
              </div>
            </div>
          </div>

          <!-- Botones de cerrar y crear -->
          <div class="modal-footer flex justify-between">
            <button type="button" (click)="cleanModalCaja()"  class="px-6 py-2.5" data-bs-dismiss="modal">
              Cerrar
            </button>
            <button type="submit" class="bg-blue-600 px-6 py-2.5 rounded-2xl text-white hover:shadow-2xl hover:animate-pulse"
            (click)="!modoEdicionCaja ? crearCaja() : editarCaja()" *ngIf="listaBultos?.includes(bultoInicialForm.value.codigoBulto) &&
            bultoInicialForm.value.cantidadSD > 0 && !bultoInicialForm.invalid"
              data-bs-dismiss="modal">
              <p *ngIf="modoEdicionCaja">Editar cambios</p>
              <p *ngIf="!modoEdicionCaja"> Ingresar</p>
          </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
