<div class="fixed inset-0 flex items-center justify-center z-50 p-4 sm:p-0 overflow-y-hidden w-full h-full">
  <!-- Contenedor Principal -->
  <div class="flex flex-col w-full lg:w-11/12 bg-gray-100 border border-gray-300 p-4 rounded-lg shadow-lg relative min-h-0 h-full overflow-hidden lg:overflow-hidden">
    <!-- Spinner de carga centrado mientras isLoading es verdadero -->
    <div *ngIf="(isLoading || isSaving) && !isCustomUpdating" class="absolute inset-0 flex flex-col items-center justify-center bg-white w-full h-full overflow-y-hidden z-50">
      <div>
        <mat-spinner></mat-spinner>
      </div>
      <div class="mt-4 text-center">
        <div *ngIf="isLoading && !isSaving">Cargando información de la revisión...</div>
        <div *ngIf="isSaving">Guardando...</div>
      </div>
    </div>

    <!-- Contenedor de Paneles -->
    <div *ngIf="isCustomUpdating || (!isLoading && !isSaving)" class="flex flex-col lg:flex-row w-full h-full overflow-y-auto lg:overflow-x-hidden">


      <!-- Panel Izquierdo -->
      <div class="flex flex-col w-full lg:w-3/5 p-4 min-h-full">

        <h3 class="text-lg font-bold text-blue-700 flex items-center mb-0">
          <mat-icon class="mr-2">assignment</mat-icon>
          Detalles revisión #{{revisionId}}
          <span *ngIf="readonly" class="flex items-center text-gray-500 ml-4">
            <mat-icon class="align-middle mr-1">lock</mat-icon>
            <span>Sólo lectura</span>
        </span>
        </h3>

        <!-- Cuadro de advertencia de solo lectura -->
        <div *ngIf="readonly || readonlyUser" class="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-2 mb-2 rounded-lg flex items-center" role="alert">
          <mat-icon class="mr-2">warning</mat-icon>
          <div>
            <p class="font-bold">Modo Sólo Lectura.</p>
            <p>
              <span *ngIf="revisionAssignedToAnotherOperator">Esta revisión está asignada a otro operador.</span>
              <span *ngIf="errorLoading">Ocurrió un error al intentar cargar información de esta revisión.</span>
              <span> No puedes realizar cambios.</span>
            </p>
          </div>
        </div>

        <!-- Grupo de pestañas principal -->
        <mat-tab-group class="flex-grow h-full lg:h-2/3 overflow-hidden" [dynamicHeight]="false" style="min-height: 300px;">
          <mat-tab>
            <ng-template mat-tab-label>
              Reproductor de Video
              <mat-progress-spinner
                *ngIf="isCustomUpdating && customDataUpdate.VIDEO"
                diameter="20"
                mode="indeterminate"
                class="ml-2 align-middle">
              </mat-progress-spinner>
            </ng-template>
            <div class="w-full h-full">
              <video #videoElement controls controlsList="nodownload" class="w-full h-full bg-black rounded-md"
                     *ngIf="detalleRevision?.videoURL">
                <source [src]="detalleRevision.videoURL" type="video/mp4" />
                Tu navegador no soporta la reproducción de este video.
              </video>
            </div>
          </mat-tab>



          <mat-tab>
            <ng-template mat-tab-label>
              {{'Galería de Screenshots (' + (detalleRevision.screenshots?.length || 0) + ')'}}
              <mat-progress-spinner
                *ngIf="isCustomUpdating && customDataUpdate.SCREENSHOTS"
                diameter="20"
                mode="indeterminate"
                class="ml-2 align-middle">
              </mat-progress-spinner>
            </ng-template>
            <div class="bg-gray-100 p-4 rounded-md w-full h-full overflow-auto">
              <mat-grid-list cols="3" rowHeight="120px" gutterSize="10px">
                <mat-grid-tile *ngFor="let screenshot of detalleRevision.screenshots">
                  <div class="flex flex-col items-center space-y-1">
                    <img [src]="screenshot.presignedURL"
                         class="w-full h-20 bg-gray-300 rounded-md cursor-pointer object-cover"
                         (click)="openModal(screenshot)" />
                    <p class="text-sm text-center whitespace-nowrap">
                      {{ formatTimeFromSeconds(screenshot.timeSeconds) }}<br />
                      (Frame: {{ screenshot.frameNumber }})
                    </p>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
            </div>
          </mat-tab>


          <mat-tab >
            <ng-template mat-tab-label>
              {{'Conflictos (' + (detalleRevision.conflictos?.length || 0) + ')'}}
              <mat-progress-spinner
                *ngIf="isCustomUpdating && customDataUpdate.CONFLICTS"
                diameter="20"
                mode="indeterminate"
                class="ml-2 align-middle">
              </mat-progress-spinner>
            </ng-template>
            <div class="p-4 max-h-[calc(100vh-300px)] overflow-y-auto">
              <div *ngIf="detalleRevision.conflictos && detalleRevision.conflictos.length > 0 && !(isCustomUpdating && customDataUpdate.CONFLICTS); else noConflictos">
                <div *ngFor="let conflicto of detalleRevision.conflictos"
                     [ngClass]="{'bg-red-100': !conflicto.canBeBypassed || !bypassRevisionConflicts, 'bg-white': conflicto.canBeBypassed && bypassRevisionConflicts}"
                     class="border border-red-400 rounded-lg mb-3 p-3 shadow-sm">
                  <div class="flex items-center gap-2 mb-2">
                    <mat-icon [ngClass]="{'text-red-600': !bypassRevisionConflicts, 'text-orange-600': bypassRevisionConflicts}">warning</mat-icon>
                    <h3 class="text-base font-bold" [ngClass]="{'text-red-600': !bypassRevisionConflicts, 'text-orange-600': bypassRevisionConflicts}">
                      {{ conflicto.nombreParaMostrar }}
                    </h3>
                  </div>
                  <div class="text-sm text-gray-700">
                    <p *ngIf="conflicto.scope == 'LOCAL'" class="mb-1"><strong>Campo:</strong> {{ conflicto.nombreCampo }}</p>
                    <p *ngIf="conflicto.scope == 'LOCAL'"class="mb-1"><strong>Valor del Campo:</strong> {{ conflicto.valorCampo }}</p>
                    <p class="mb-1"><strong>Detalle:</strong> {{ conflicto.detalle }}</p>
                    <p *ngIf="conflicto.scope == 'LOCAL'"class="mb-1"><strong>Revisiones Relacionadas:</strong> {{ conflicto.revisionIds.join(', ') }}</p>
                  </div>
                </div>
              </div>
              <ng-template #noConflictos>
                <p *ngIf="(isCustomUpdating && customDataUpdate.CONFLICTS)" class="text-sm text-gray-700"> Cargando...</p>
                <p *ngIf="!(isCustomUpdating && customDataUpdate.CONFLICTS)" class="text-sm text-gray-700">No se han detectado conflictos para esta revisión.</p>
              </ng-template>
            </div>
          </mat-tab>

          <mat-tab>
            <ng-template mat-tab-label>
              {{'Historial (' + (detalleRevision.historial?.length || 0) + ')'}}
              <mat-progress-spinner
                *ngIf="isCustomUpdating && customDataUpdate.HISTORY"
                diameter="20"
                mode="indeterminate"
                class="ml-2 align-middle">
              </mat-progress-spinner>
            </ng-template>
            <div class="p-2 max-h-[calc(100vh-300px)]">
              <div *ngIf="detalleRevision.historial && detalleRevision.historial.length > 0  && !(isCustomUpdating && customDataUpdate.HISTORY); else noHistorial">
                <table class="min-w-full bg-white border border-gray-200 text-sm">
                  <thead>
                  <tr>
                    <th class="py-1 px-2 border-b border-gray-300 text-left">Acción</th>
                    <th class="py-1 px-2 border-b border-gray-300 text-left">Operador</th>
                    <th class="py-1 px-2 border-b border-gray-300 text-left">Fecha y Hora</th>
                    <th class="py-1 px-2 border-b border-gray-300 text-left">Detalle</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let evento of detalleRevision.historial" class="hover:bg-gray-100">
                    <td class="py-1 px-2 border-b border-gray-300">{{ getTipoEventoById(evento.idEvento).nombre }}</td>
                    <td class="py-1 px-2 border-b border-gray-300">{{ evento.idOperador }}</td>
                    <td class="py-1 px-2 border-b border-gray-300">{{ evento.timestamp | date:'dd-MM-yyyy HH:mm:ss' }}</td>
                    <td class="py-1 px-2 border-b border-gray-300">
                      <div *ngIf="evento.detalle?.correccion">
                        <p class="mb-1"><strong>ID Incidencia:</strong> #{{ evento.detalle.correccion.id }}</p>
                        <p><strong>Problemas:</strong></p>
                        <ul class="list-disc ml-4 inline-block">
                          <li *ngFor="let problema of evento.detalle.problemas" class="inline-block mr-2">
                            - [#{{problema.idMotivo}}] {{ getMotivoById(problema.idMotivo).nombre }}
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <ng-template #noHistorial>
                <p *ngIf="(isCustomUpdating && customDataUpdate.HISTORY)" class="text-sm text-gray-700">Cargando...</p>
                <p *ngIf="!(isCustomUpdating && customDataUpdate.HISTORY)" class="text-sm text-gray-700">No se ha registrado historial para esta revisión.</p>
              </ng-template>
            </div>
          </mat-tab>

        </mat-tab-group>

        <div class="bg-white shadow-md p-2 rounded-md flex flex-col">
          <div class="grid grid-cols-2 gap-4">
            <!-- Estado Revisión -->
            <div>
              <p class="text-sm font-bold text-gray-700 flex items-center">
                <mat-icon class="mr-2">report</mat-icon>
                Estado revisión:
              </p>
              <p class="text-sm" [ngStyle]="{'color': service.getEstadoById(detalleRevision.estadoId).display_color}">
                {{ service.getEstadoById(detalleRevision.estadoId).display_text }}
              </p>
            </div>


            <!-- Rut Docente -->

            <div>
              <p class="text-sm font-bold text-gray-700 flex items-center">
                <mat-icon class="mr-2">badge</mat-icon>
                Rut Docente:
              </p>
              <p class="text-sm">{{ detalleRevision.rutDocente }}</p>
            </div>

            <!-- Número Correlativo -->

            <div>
              <p class="text-sm font-bold text-gray-700 flex items-center">
                <mat-icon class="mr-2">format_list_numbered</mat-icon>
                Número correlativo:
              </p>
              <p class="text-sm">{{ detalleRevision.ordenCorrelativo }}</p>
            </div>


            <!-- Duración Total de los Videos -->

            <div>
              <p class="text-sm font-bold text-gray-700 flex items-center">
                <mat-icon class="mr-2">access_time</mat-icon>
                Duración total de los videos:
              </p>

              <p class="text-sm" *ngIf="detalleRevision.duracionTotalVideos">{{ detalleRevision.duracionTotalVideos | formatTime }}</p>
            </div>

            <!-- Archivado -->

            <div *ngIf="detalleRevision.archivado">
              <p class="text-sm font-bold text-gray-700 flex items-center">
                <mat-icon class="mr-2">archive</mat-icon>
                Archivado
              </p>
              <p class="text-sm text-red-600">Archivado</p>
            </div>

            <!-- Detalle de Agrupación -->
            <div>
              <p class="text-sm font-bold text-gray-700 flex items-center">
                <mat-icon class="mr-2">school</mat-icon>
                Agrupación:
              </p>
              <p class="text-sm">
                {{ detalleRevision.agrupacionAsignatura?.codigoAgrupacion +": " + detalleRevision.agrupacionAsignatura?.nombreAgrupacion || 'No disponible' }}
              </p>
            </div>

            <!-- Detalle de Asignatura -->
            <div>
              <p class="text-sm font-bold text-gray-700 flex items-center">
                <mat-icon class="mr-2">book</mat-icon>
                Asignatura:
              </p>
              <p class="text-sm">
                {{ detalleRevision.agrupacionAsignatura?.codigoAsignatura +": " + detalleRevision.agrupacionAsignatura?.nombreAsignatura|| 'No disponible' }}
              </p>
            </div>
          </div>
        </div>

      </div>

      <!-- Panel Derecho -->
      <div class="w-full lg:w-2/5 p-4 flex flex-col justify-start relative min-h-full">

        <mat-tab-group class="h-full min-h-full overflow-y-auto" [dynamicHeight]="false">
          <mat-tab label="Incidencias">

            <!-- Formulario de nueva corrección o edición -->
            <ng-container *ngIf="isCreatingNewCorrection || isEditingCorrection">
              <app-nueva-correccion class="flex-grow mt-8"
                                    [revisionData]="this.detalleRevision"
                                    [edit]="isEditingCorrection"
                                    (guardarCorreccion)="saveCorrection($event)"
                                    (cancelarCorreccion)="cancelNewCorrection()"></app-nueva-correccion>
            </ng-container>

            <div *ngIf="isLoading && isCustomUpdating && customDataUpdate?.CORRECTIONS" class="inset-0 flex flex-col items-center justify-center bg-transparent overflow-y-hidden z-50">
              <div>
                <mat-spinner></mat-spinner>
              </div>
              <div class="mt-4 text-center">
                <div>Cargando...</div>
              </div>
            </div>

            <!-- Mostrar botón de nueva corrección y correcciones existentes -->
            <ng-container *ngIf="!isCreatingNewCorrection && !isEditingCorrection && !(isCustomUpdating&&customDataUpdate?.CORRECTIONS)">

              <!-- Botón "Nueva corrección" (bloqueado si es readonly) -->
              <div *ngIf="this.correccion == null && (detalleRevision.estadoId == 5 || detalleRevision.estadoId == 1)" class="mb-4 mt-8 flex justify-center">
                <button type="button"
                        (click)="startNewCorrection()"
                        class="w-full max-w-xs text-white font-medium py-2 rounded-lg flex items-center justify-center shadow-md transition-transform transform"
                        [class.bg-green-600]="!readonly"
                        [class.hover\:scale-105]="!readonly"
                        [class.bg-gray-400]="readonly"
                        [class.cursor-not-allowed]="readonly"
                        [disabled]="readonly">
                  <mat-icon class="mr-2" *ngIf="!readonly">add_circle</mat-icon>
                  <mat-icon class="mr-2" *ngIf="readonly">lock</mat-icon>
                  <span>Registrar Incidencias</span>
                </button>
              </div>




              <!-- Corrección existente -->
              <div *ngIf="correccion != null" class="p-4 bg-gray-50 rounded-md shadow-lg mb-4 transition-transform hover:shadow-2xl flex-grow flex flex-col max-h-[80vh] min-h-0">
                <div class="flex items-center space-x-2 mb-4">
                  <mat-icon class="text-gray-500">edit</mat-icon>
                  <p class="text-md font-bold text-gray-700">Incidencia #{{ correccion.id }}</p>
                </div>

                <!-- Información de la corrección -->
                <div class="flex flex-col text-gray-500 text-sm mb-4">
                  <div class="flex flex-col mb-2">
                    <div class="flex items-center">
                      <mat-icon class="mr-1">calendar_today</mat-icon>
                      <span class="font-bold mr-1">Creación:</span>
                    </div>
                    <span>{{ correccion.createdAt | date:'dd-MM-yyyy HH:mm:ss' }}</span>
                  </div>
                  <div class="flex flex-col mb-2">
                    <div class="flex items-center">
                      <mat-icon class="mr-1">update</mat-icon>
                      <span class="font-bold mr-1">Ult. modificación:</span>
                    </div>
                    <span>{{ correccion.updatedAt | date:'dd-MM-yyyy HH:mm:ss' }}</span>
                  </div>
                  <div class="flex flex-col">
                    <div class="flex items-center">
                      <mat-icon class="mr-1">person</mat-icon>
                      <span class="font-bold mr-1">Ult. operador:</span>
                    </div>
                    <span>{{ correccion.idOperador }}</span>
                  </div>
                </div>

                <!-- Problemas asociados -->

                <div class="mt-4 flex-grow flex flex-col overflow-hidden">
                  <div class="font-bold text-gray-700 mb-2 flex items-center">
                    <mat-icon class="mr-1">error_outline</mat-icon>
                    Problemas asociados:
                  </div>

                  <div class="overflow-y-auto flex-grow">
                    <ul class="space-y-2">
                      <li *ngFor="let problema of correccion.problemas" class="bg-gray-50 p-3 rounded-lg shadow-md">
                        <div class="flex items-center justify-between">
                          <!-- Código de Categoría y Motivo unidos, seguido del Nombre del Problema -->
                          <div class="flex items-center">
                            <!-- Código de la Categoría -->
                            <span class="bg-gray-200 px-2 py-1 rounded-l text-xs font-bold">
              {{ problema.codigoCategoria }}
            </span>

                            <!-- Código del Motivo -->
                            <span class="bg-blue-100 px-2 py-1 rounded-r text-xs font-bold -ml-px">
              {{ problema.codigoMotivo }}
            </span>

                            <!-- Nombre del Problema -->
                            <p class="ml-2 font-semibold text-gray-800 text-sm">
                              {{ getMotivoById(problema.idMotivo).nombre }}
                            </p>

                            <!-- Icono de ayuda con descripción -->
                            <mat-icon
                              matTooltip="{{ getMotivoById(problema.idMotivo).descripcion }}"
                              class="text-blue-500 text-sm cursor-pointer ml-1"
                            >
                              help_outline
                            </mat-icon>
                          </div>
                        </div>

                        <!-- Indicador de "No Solucionable" -->
                        <div *ngIf="!getMotivoById(problema.idMotivo).solucionable" class="flex items-center space-x-1 mt-1">
                          <p class="text-xs text-red-600 font-semibold">No Solucionable</p>
                          <mat-icon
                            matTooltip="Este problema no puede ser solucionado por el operador. La revisión se marcará como fallida y requerirá intervención adicional."
                            class="text-red-500 text-sm cursor-pointer"
                          >
                            help_outline
                          </mat-icon>
                        </div>

                        <!-- Detalles de Valores Anterior y Nuevo cuando es solucionable -->
                        <div *ngIf="getMotivoById(problema.idMotivo).solucionable && MOTIVOS_CAMBIO_VALOR.includes(problema.idMotivo)" class="mt-1 text-xs text-gray-600">
                          <p><strong>Valor Anterior:</strong> {{ problema.valorAnterior ?? 'N/A' }}</p>
                          <p><strong>Valor Nuevo:</strong> {{ problema.valorNuevo ?? 'N/A' }}</p>
                        </div>

                        <!-- Comentario -->
                        <ng-container *ngIf="problema.comentario">
                          <details class="mt-2 bg-gray-50 rounded-lg p-2 shadow-inner">
                            <summary class="text-blue-600 text-xs font-semibold cursor-pointer flex items-center">
                              <mat-icon class="mr-1">comment</mat-icon>
                              <span>Ver comentario</span>
                            </summary>
                            <p class="mt-2 text-gray-700 bg-gray-100 p-2 rounded break-words">
                              {{ problema.comentario }}
                            </p>

                          </details>
                        </ng-container>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="flex justify-end space-x-2 mt-4 mb-2 flex-shrink-0">

                  <!-- Botón Editar (solo visible si es permitido editar) -->
                  <button *ngIf="detalleRevision.estadoId == 5 || detalleRevision.estadoId == 1" mat-raised-button color="accent"
                          (click)="editCorrection(correccion.id)" [disabled]="readonly">
                    <mat-icon class="mr-1">edit</mat-icon>
                    Editar
                  </button>

                  <!-- Botón Eliminar -->
                  <button *ngIf="detalleRevision.estadoId == 5 || detalleRevision.estadoId == 1" mat-raised-button color="warn"
                          (click)="deleteCorrection(correccion.id)" [disabled]="readonly">
                    <mat-icon class="mr-1">delete</mat-icon>
                    Eliminar
                  </button>
                </div>

              </div>


            </ng-container>
          </mat-tab>

          <!-- Tab para Rúbrica -->
          <mat-tab label="Rúbrica">

            <div class="p-4 h-full">
              <app-rubrica-revision [asignadoAEsteOperador]="!revisionAssignedToAnotherOperator" (successfulSaved)="onRubricaAnswerSaved($event)"  [rutDocente]="detalleRevision.rutDocente"></app-rubrica-revision>
            </div>

          </mat-tab>
        </mat-tab-group>


      </div>
    </div>

    <!-- Footer del Contenedor Principal -->
    <div class="w-full p-4 flex justify-between items-center max-h-6">
      <div *ngIf="!isLoading && !(isEditingCorrection || isCreatingNewCorrection)" class="flex flex-grow w-full justify-between h-full items-center">
      <button type="button" class="bg-gray-300 hover:bg-gray-500 text-black py-2 px-4 rounded-md text-sm flex items-center" [mat-dialog-close]="true">
        <mat-icon class="mr-1">close</mat-icon>
        Cerrar ventana
      </button>

      <!-- Botones Inferiores -->
      <div class="flex space-x-4">
        <!-- Botón Reabrir Revisión (bloqueado si es readonly) -->
        <button *ngIf=" canReOpenRevision"
                (click)="reopenRevision()" type="button"
                class="text-white py-2 px-4 rounded-md text-sm flex items-center justify-center"
                [class.bg-orange-600]="!readonly"
                [class.hover\:bg-orange-700]="!readonly"
                [class.bg-gray-400]="readonly"
                [class.cursor-not-allowed]="readonly"
                [disabled]="!canReOpenRevision">
          <mat-icon class="mr-2" *ngIf="!readonly">folder_open</mat-icon>
          <mat-icon class="mr-2" *ngIf="readonly">lock</mat-icon>
          <span >Reabrir revisión</span>
        </button>

        <!-- Botón Finalizar Revisión (bloqueado si es readonly) -->
        <button *ngIf="this.detalleRevision?.estadoId == 1 || this.detalleRevision?.estadoId == 5"
                (click)="closeRevision()" type="button"
                class="text-white py-2 px-4 rounded-md text-sm flex items-center justify-center btn-sm"
                [class.bg-blue-600]="!readonly"
                [class.hover\:bg-blue-700]="!readonly"
                [class.bg-gray-400]="readonly"
                [class.cursor-not-allowed]="readonly"
                [disabled]="readonly">
          <mat-icon class="mr-2" *ngIf="!readonly">done</mat-icon>
          <mat-icon class="mr-2" *ngIf="readonly">lock</mat-icon>
          <span *ngIf="detalleRevision.totalCorrecciones == 0">Finalizar revisión sin incidencias</span>
          <span *ngIf="detalleRevision.totalCorrecciones > 0">Finalizar revisión con incidencias</span>
        </button>
      </div>
      </div>
    </div>
  </div>
</div>
