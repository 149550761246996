export const environment = {
  production: true,
  cognitoUserPoolId:'us-east-1_4Qv66ekWp',
  cognitoClietId:'1nldapupnum76bc6ikn74d8k09',
  ACCESS_TOKEN: 'accessToken',
  AUTHORIZATION: 'Authorization',
  BEARER: 'Bearer ',
  protocol: 'https',
  host: 'operaciones.iie.cl',
  hostRegistro: '',
  hostdm: 'app.docentemas.cl',
  isDevOrLocal: false,

  features: {
    revisionesGrabaciones: {
      bypassOperatorAssignment: false,  //flag para activar o desactivar el bloqueo de acciones sobre revisiones/correcciones asignadas a otro operador
      bypassRevisionConfitcs: false,  //flag para advertir sobre bloqueo de revisiones con conflictos
      reopenRevision: true, //flag para activar o desactivar la reapertira de revisiones
    }
  }
};
