export const MOTIVOS = {
  SOBREAUSENTE: 1,
  RUT_ILEGIBLE: 2,
  RUT_NO_CORRESPONDE: 3,
  RUT_CON_ERROR_EN_DIGITO: 4,
  CORRELATIVO_INCORRECTO: 5,
  VIDEO_NO_CORRESPONDE_A_CLASE: 6,
  SALTOS_TIEMPO_VIDEO: 8,
  VIDEO_REPETIDO: 9,
  CORTE_ABRUPTO_FINAL_VIDEO: 10,
  OTROS: 11,
  PANEO_SEGUIMIENTO_INADECUADO: 12,
  CAMARA_ENFOCA_IRRELEVANTE: 15,
  IMAGEN_EN_NEGRO: 16,
  MALA_ILUMINACION: 17,
  MALA_CALIDAD_IMAGEN: 18,
  OTROS_PROBLEMAS_IMAGEN: 19,
  SIN_AUDIO_O_CON_CORTES: 20,
  DESFASE_AUDIO: 21,
  RUIDO_AMBIENTE_IMPIDE_COMPRENSION: 22,
  AUDIO_MUY_BAJO: 23,
  OTRAS_INTERFERENCIAS: 24,
  DISCONTINUIDAD_ENTRE_VIDEOS: 25
};
export const MOTIVOS_CAMBIO_VALOR : number [] = [//motivos para los cuales es necesario mostrar "valor anterior" y "valor nuevo"
  MOTIVOS.RUT_CON_ERROR_EN_DIGITO,
  MOTIVOS.RUT_NO_CORRESPONDE,
  MOTIVOS.CORRELATIVO_INCORRECTO]
