<div *ngIf="isLoading" class="flex items-center justify-center">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isLoading" class="relative">
  <h2 class="text-lg font-semibold mb-4">Complete las Respuestas</h2>
  <p class="text-gray-700 mb-6">Seleccione la opción que considere correcta para cada pregunta. Las respuestas se guardarán automáticamente al realizar una selección.</p>

  <!-- Ícono de candado si el usuario es readonly -->
  <div *ngIf="readonly" class="absolute top-2 right-2 z-10 flex items-center text-gray-500">
    <mat-icon>lock</mat-icon>
  </div>

  <!-- Spinner en la esquina superior derecha dentro del componente -->
  <div *ngIf="isSaving" class="absolute top-2 right-12 z-10">
    <mat-spinner diameter="30"></mat-spinner>
  </div>

  <!-- Formulario de preguntas -->
  <form class="space-y-4">

    <ng-container *ngFor="let grupo of preguntasAgrupadas">
      <!-- Pregunta principal -->
      <div
        *ngIf="mostrarRubrica(grupo.principal.rubricaId)"
        class="relative p-4 rounded-md shadow border-l-4 transition-all"
        [ngClass]="{
      'border-emerald-500 bg-emerald-50': estaRespondida(grupo.principal.rubricaId),
      'border-amber-500 bg-gray-50': !estaRespondida(grupo.principal.rubricaId),
      'border-blue-400 bg-blue-50 opacity-75 pointer-events-none': estaGuardando(grupo.principal.rubricaId)
    }"
      >
        <!-- Número de la Pregunta -->
        <div class="absolute -top-3 -left-3 bg-indigo-500 text-white rounded-full w-6 h-6 flex items-center justify-center">
          {{ grupo.principal.numero }}
        </div>

        <!-- Título de la Pregunta -->
        <div class="flex items-center space-x-2">
          <p class="font-medium text-gray-800">
            {{ grupo.principal.pregunta }}
          </p>
        </div>

        <!-- Respuesta -->
        <mat-radio-group
          [disabled]="!asignadoAEsteOperador || isSaving || readonly || grupo.principal.bloqueado"
          [ngModel]="getRespuesta(grupo.principal.rubricaId)"
          (ngModelChange)="setRespuesta(grupo.principal.rubricaId, $event)"
          name="respuesta-{{ grupo.principal.rubricaId }}"
          class="mt-2"
        >
          <mat-radio-button *ngFor="let alternativa of grupo.principal.alternativas" [value]="alternativa.id">
            {{ alternativa.respuesta }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <!-- Preguntas condicionadas -->
      <ng-container *ngFor="let preguntaCondicionada of grupo.condicionadas">
        <div
          *ngIf="mostrarRubrica(preguntaCondicionada.rubricaId)"
          class="relative p-3 rounded-md shadow border-l-4 ml-6 transition-all"
          [ngClass]="{
        'border-emerald-500 bg-emerald-50': estaRespondida(preguntaCondicionada.rubricaId),
        'border-amber-500 bg-gray-50': !estaRespondida(preguntaCondicionada.rubricaId),
        'border-blue-400 bg-blue-50 opacity-75 pointer-events-none': estaGuardando(preguntaCondicionada.rubricaId)
      }"
        >
          <!-- Número de la Pregunta Condicionada -->
          <div class="absolute -top-3 -left-3 bg-blue-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-sm">
            {{ preguntaCondicionada.numero }}
          </div>

          <!-- Título de la Pregunta Condicionada -->
          <div class="flex items-center space-x-2">
            <p class="font-medium text-blue-800">
              {{ preguntaCondicionada.pregunta }}
            </p>
          </div>

          <!-- Respuesta -->
          <mat-radio-group
            [disabled]="!asignadoAEsteOperador || isSaving || readonly || preguntaCondicionada.bloqueado"
            [ngModel]="getRespuesta(preguntaCondicionada.rubricaId)"
            (ngModelChange)="setRespuesta(preguntaCondicionada.rubricaId, $event)"
            name="respuesta-{{ preguntaCondicionada.rubricaId }}"
            class="mt-2"
          >
            <mat-radio-button *ngFor="let alternativa of preguntaCondicionada.alternativas" [value]="alternativa.id">
              {{ alternativa.respuesta }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </ng-container>
    </ng-container>

  </form>
</div>
