import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, HostListener, Injector, NgZone, TemplateRef, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SafeResourceUrl } from '@angular/platform-browser';
import { faFileExcel, faSpinner } from '@fortawesome/free-solid-svg-icons';
import 'moment-timezone';
import { take } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { PaginationOptions } from 'src/app/base/models/paginationOptions';
import { AutorizacionesService } from 'src/app/base/services/autorizaciones.service';
import { ErrorMensajeService } from 'src/app/base/services/errorMensaje.service';
import { Docente } from 'src/app/registros/models/docente';
import { HistorialValidacion } from 'src/app/registros/models/historialValidacion';
import Swal from 'sweetalert2';

import * as moment from 'moment';
import {
  Collapse,
  Dropdown,
  Ripple,
  SmoothScroll,
  initTE
} from "tw-elements";
import { Toast } from '../../../base/services/toast';
import { EstadoRegistro } from '../../models/estadoRegistro';
import { FichaDocenteFilter } from '../../models/fichaDocenteFilter';
import { InfoSuspensionEximicion } from '../../models/infoSuspensionEximicion';
import { NivelDocente } from '../../models/nivelDocente';
import { Rbd } from '../../models/rbd';
import { Subsectores } from '../../models/subsectores';
import { FichaDocenteService } from '../../services/ficha-docente';
import { ReportesService } from '../../services/reportes.service';

@Component({
  selector: 'app-ficha-docente',
  templateUrl: './ficha-docente.component.html',
  styleUrls: ['./ficha-docente.component.css']
})
export class FichaDocenteComponent {
  faExcel = faFileExcel;
  faSpinner = faSpinner;
  estaDescargandoExcel: boolean = false;
  estadosInscripcion: string[] = ['En Revisión','Inscrito', 'Desinscrito', 'Pendiente','Cancelado' ];
  estadosValidacion: {label: string, value: any}[] = [
    {
      label: 'Validado',
      value: 3
    },
    {
      label: 'No Validado',
      value: 1
    }];
  token: any;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('primerMenuAcordeon') primerMenuAcordeon: ElementRef;
  @ViewChild('primerMenuEdicion') primerMenuEdicion: ElementRef;
  @ViewChild('infoDatosPersonales') infoDatosPersonales: TemplateRef<any>;
  @ViewChild('infoDatosEstablecimiento') infoDatosEstablecimiento: TemplateRef<any>;
  @ViewChild('infoDatosInscripcion') infoDatosInscripcion: TemplateRef<any>;
  @ViewChild('infoValidacion') infoValidacion: TemplateRef<any>;
  @ViewChild('infoEximirSuspender') infoEximirSuspender: TemplateRef<any>;
  @ViewChild('infoHistorial') infoHistorial: TemplateRef<any>;

  atributosNulosOVacios: string[] = [];

  acordeonesInfoDocenteName: string = "acordeonInfoDocente";
  acordeonesInfoDocente: {
    content: TemplateRef<any>,
    id: string,
    title: string,
    target: string,
    showFirst: boolean,
    keepOpen: boolean,
    context: any,
    injector:any,
  }[] = [];

  @ViewChild('editarDatosPersonales') editarDatosPersonales: TemplateRef<any>;
  @ViewChild('editarDatosContacto') editarDatosContacto: TemplateRef<any>;
  @ViewChild('editarEstablecimiento') editarEstablecimiento: TemplateRef<any>;
  @ViewChild('editarInscripcion') editarInscripcion: TemplateRef<any>;

  editarInfoDocente!: boolean;
  acordeonesEditarDocenteName: string = "acordeonEditarDocente";
  acordeonesEditarDocente: {
    content: TemplateRef<any>,
    id: string,
    title: string,
    target: string,
    showFirst: boolean,
    keepOpen: boolean,
    context: any,
    injector:any,
  }[] = [];

  fechaCorteValidacion!: Date;

  personalDataSection: { key: string, value: string }[] = [
    { key: 'rutDocente', value: 'Rut Docente' },
    { key: 'nombres', value: 'Nombres' },
    { key: 'apellidos', value: 'Apellidos' },
    { key: 'email', value: 'Email' },
    { key: 'numeroContacto1', value: 'Teléfono' },
    { key: 'numeroContacto2', value: 'Teléfono Opcional' },
  ]

  establishmentDataSection: { key: string, value: string }[] = [
    { key: 'nombreEstablecimiento', value: 'Nombre Establecimiento' },
    { key: 'rbd', value: 'RBD' },
    { key: 'rutSostenedor', value: 'Rut Sostenedor' },
    { key: 'nombreSostenedor', value: 'Nombre Sostenedor' },
  ]

  inscriptionDataTableSection: { key: string, value: string }[] = [
    { key: 'estadoRegistro', value: 'Estado Registro' },
    { key: 'debeRendir', value: 'Debe Rendir' },
    { key: 'codNivel', value: 'Agrupación' },
    { key: 'codSubsector', value: 'Asignatura' },
  ]

  inscriptionDataSection: { key: string, value: string }[] = [
    { key: 'estadoRegistro', value: 'Estado Registro' },
    { key: 'debeRendir', value: 'Debe Rendir' },
    { key: 'codNivel', value: 'Agrupación' },
    { key: 'codSubsector', value: 'Asignatura' },
    { key: 'motivoNoInscripcion', value: 'Motivo no Inscripción' },
    { key: 'fechaInscripcion', value: 'Fecha Inscripción' },
    { key: 'fechaUltMod', value: 'Fecha Ultima Modificación' },
  ]

  validationDataTableSection: { key: string, value: string }[] = [
    { key: 'estadoValidacion', value: 'Estado Validación' },
  ]

  validationDataSectionWithoutChanges: { key: string, value: string }[] = [
    { key: 'estadoValidacion', value: 'Estado Validación' },
    { key: 'fechaValidacion', value: 'Fecha Validación' },
    { key: 'beneficios', value: 'Beneficios' },
    { key: 'agnoPortafolioCorregido', value: 'Últ. Portafolio Corregido'},
    { key: 'solicitaCambioSubs', value: 'Solicita Cambio Agrupación/Asignatura' }
  ];

  validationDataSection: { key: string, value: string }[] = this.validationDataSectionWithoutChanges;

  validationDataSectionFull: { key: string, value: string }[] = [
    { key: 'estadoValidacion', value: 'Estado Validación' },
    { key: 'fechaValidacion', value: 'Fecha Validación' },
    { key: 'agnoPortafolioCorregido', value: 'Últ. Portafolio Corregido'},
    { key: 'beneficios', value: 'Beneficios' },
    { key: 'solicitaCambioSubs', value: 'Solicita Cambio Agrupación/Asignatura' },
    { key: 'nivelPrevio', value: 'Agrupación desde Inscripción' },
    { key: 'subsectorPrevio', value: 'Asignatura desde Inscripción' },
    { key: 'nivelSolicitado', value: 'Agrupación Solicitada' },
    { key: 'subsectorSolicitado', value: 'Asignatura Solicitada' },
    { key: 'estadoConfirmacionSubsector', value: 'Estado Cambio Agrupación/Asignatura' },
  ]

  exemptSuspendDataSection: { key: string, value: string }[] = [
    { key: 'fechaSolicitud', value: 'Fecha de Solicitud Suspender '},
    { key: 'fechaResultado', value: 'Fecha Respuesta Sostenedor'},
    { key: 'estadoEximirSuspender', value: 'Estado Eximir Suspender' },
    { key: 'motivoSuspenderEximir', value: 'Motivo Eximir Suspender' },
  ]


  keyValue: { key: string, value: string }[] = this.personalDataSection
    .concat(this.establishmentDataSection)
    .concat(this.inscriptionDataSection)
    .concat(this.validationDataSection)

  keyValueTable: { key: string, value: string }[] = this.personalDataSection
    .concat(this.establishmentDataSection)
    .concat(this.inscriptionDataTableSection)
    .concat(this.validationDataTableSection);

  displayedColumnsModal: { [key: string]: string } = this.keyValue.reduce((acc: { [key: string]: string }, curr) => {
    acc[curr.key] = curr.value;
    return acc;
  }, {});

  displayedColumnsTable: { [key: string]: string } = this.keyValueTable.reduce((acc: { [key: string]: string }, curr) => {
    acc[curr.key] = curr.value;
    return acc;
  }, {});

  displayedColumns: string[] = Object.keys(this.displayedColumnsModal);
  nameDisplayedColumns: string[] = Object.values(this.displayedColumnsTable);

  toggleColumns: { key: string, value: boolean, name: string }[] = [
    { key: 'nombres', value: true, name: 'Nombres' },
    { key: 'apellidos', value: true, name: 'Apellidos' },
    { key: 'contacto', value: true, name: 'Contacto' },
    { key: 'establecimiento', value: true, name: 'Establecimiento & RBD' },
    { key: 'nombreSostenedor', value: true, name: 'Sostenedor' },
    { key: 'estadoRegistro', value: true,  name: 'Estado Registro' },
    { key: 'debeRendir', value: true, name: 'Debe Rendir' },
    { key: 'estadoValidacion', value: true, name: 'Estado Validación' },
    { key: 'fechaValidacion', value: true, name: 'Fecha Validación' },
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('iframe', {static:false}) iframe!: ElementRef;
  @ViewChild('modalElement') modalElement!: ElementRef;
  estaMenuDatosPersonalesAbierto!: boolean;
  estaMenuEdicionDatosPersonalesAbierto!: boolean;
  isLoading = false;
  mensajeError: boolean = false;
  dataSource: MatTableDataSource<Docente> = new MatTableDataSource();
  pageIndex: number;
  length: number;
  filtros: FichaDocenteFilter = new FichaDocenteFilter();
  docenteSuspenderEximir: InfoSuspensionEximicion = new InfoSuspensionEximicion();
  response: any;
  options: PaginationOptions = new PaginationOptions();
  docenteSeleccionadoModal!: any;
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;

  permisoAccionesEstadoDocente: boolean = false;
  permisoEdicionDocentes: boolean = false;
  urlPDF: SafeResourceUrl = null;
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  rutInfoSuspender: string;
  nombreInfoSuspender: string;
  listaRBD: Rbd[] = null;
  listaRBDFiltrada: Rbd[] = null;
  listaEstados: EstadoRegistro[] = null;
  listaNiveles: NivelDocente[] = null;
  listaSubsectores: Subsectores[] = null;
  subsectores: Subsectores[] = null;
  establecimientoSeleccionado: Rbd;
  rbdControl = new FormControl('');
  historialValidacion: [null | HistorialValidacion];

  openModalButton = document.getElementById('openModalButton');
  closeModalButton = document.getElementById('closeModal');
  modal = document.getElementById('myModal');
  modalEditarDocenteOn: boolean = true;

  editarDocenteFormGroup = this._formBuilder.group({
    idRegistro: [0,],
    idValidacion: [0,],
    rutDocente: [0, [Validators.required, Validators.minLength(7)]],
    nombres: ['', Validators.required],
    primerApellido: ['', Validators.required],
    segundoApellido: ['', Validators.required],
    rbd: [0, Validators.required],
    establecimiento: [''],
    email: ['', Validators.email],
    numeroContacto1: [0,],
    numeroContacto2: [0,],
    estadoRegistro: ['',],
    codNivel: ['',],
    codSubsector: ['',],
    motivoNoInscripcion: ['',],
  });

  nombreCampos: { [string: string]:string }  =  {
    "rutDocente": "Rut Docente*",
    "nombres": "Nombres*",
    "primerApellido": "Primer Apellido*",
    "segundoApellido": "Segundo Apellido*",
    "rbd": "RBD*",
    "establecimiento": 'Establecimiento*',
    "email": 'Correo*',
    "numeroContacto1": 'Número Contacto 1',
    "numeroContacto2": 'Número Contacto 2',
    "estadoRegistro": 'Estado Registro *',
    "codNivel": "Agrupación",
    "codSubsector": "Asignatura",
    "motivoNoInscripcion": "Motivo No Inscripcion",
  };

  formularioDatosPersonales:{variable:string, etiqueta:string, tipoDato:string, esReadOnly: string}[] = [
    {variable:"rutDocente", etiqueta: "Rut Docente", tipoDato:"number", esReadOnly:"true"},
    {variable:"nombres", etiqueta: "Nombres", tipoDato:"text", esReadOnly:"false"},
    {variable:"primerApellido", etiqueta: "Primer Apellido", tipoDato:"text", esReadOnly:"false"},
    {variable:"segundoApellido", etiqueta: "Segundo Apellido", tipoDato:"text", esReadOnly:"false"},
  ];
  formularioDatosContacto = [
    {variable:"email", etiqueta: "Correo", tipoDato:"text", esReadOnly:"false"},
    {variable:"numeroContacto1", etiqueta: "Número de contacto", tipoDato:"number", esReadOnly:"false"},
    {variable:"numeroContacto2", etiqueta: "Número opcional", tipoDato:"number", esReadOnly:"false"},
  ];
  formularioDatosEstablecimiento = [
    {variable:"rbd", etiqueta: "RBD", tipoDato:"number", esReadOnly:"false"},
    {variable:"establecimiento", etiqueta: "Establecimiento", tipoDato:"string", esReadOnly:"true"},

  ];

  estadoDocenteSeleccionado: boolean = false;
  rbdTxtSeleccionado: string = '';
  mostrarInfoDocente: boolean;
  doneTimeline: boolean;
  permisoDescargaDocentesCSV: boolean;


  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event: any) {
    event.preventDefault();
  }

  constructor(
    private fichaDocenteService: FichaDocenteService,
    private reporteService: ReportesService,
    private errorMensaje: ErrorMensajeService,
    private permisoPara: AutorizacionesService,
    private _formBuilder: FormBuilder,
    private _ngZone: NgZone
  ) { }

  triggerResize() {
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = "Elementos por página"
    this.paginator._intl.nextPageLabel = 'Siguiente página';
    this.paginator._intl.previousPageLabel = 'Página Anterior';
    this.paginator._intl.lastPageLabel = 'Ir a la última página';
    this.paginator._intl.firstPageLabel = 'Ir a la primera página';
    this.dataSource.paginator = this.paginator;

  }

  ngOnInit(): void {

    initTE({ Dropdown, Ripple, Collapse, SmoothScroll });
    this.docenteSeleccionadoModal = {
      rutDocente: {
        val: 0,
      }
    }
    this.getFechaCorte();
    this.filtros = new FichaDocenteFilter();
    this.getDocentes();
    this.permisoEdicionDocentes = this.permisoPara.permisoAccionesEstadoDocente();
    this.permisoDescargaDocentesCSV = this.permisoPara.permisoDescargaDocentesCSV();
    this.nameDisplayedColumns.push('Acciones');
    if (this.permisoEdicionDocentes) {
      this.obtenerDatosFormulario();
    }
  }

  getFechaCorte() {
    this.fichaDocenteService.getFechaCorte().subscribe(
      {
        next: (response:any) => {
          if (response) this.fechaCorteValidacion = new Date(response.fecha);
        },
        error: (error:any) => {
          this.fechaCorteValidacion = new Date("2024-06-19T00:00:00");
        }
      }
    );
  }

  getDocentes(): void {
    this.isLoading = true;
    this.fichaDocenteService.docentesList(this.options, this.filtros.rutDocente,
      this.filtros.rutSostenedor,
      this.filtros.rbd,
      this.filtros.estadoRegistro,
      this.filtros.estadoValidacion).subscribe(
      { next : (response:any) => {
      this.dataSource = new MatTableDataSource<Docente>(response.list);
      this.response = response.list;
      this.pageIndex = (response.pageNum - 1);
      this.length = response.total;
      this.isLoading = false;
      }, error: (error: any) => {
        this.isLoading = false;
        this.errorMensaje.show(error, "obtener los docentes").then((result:any) => {
          if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
            this.isLoading = false;
          }
        });
      }});

  }

  obtenerSubsectores(codigo: string) {
    if (!this.listaNiveles) return;
    const codigoSubsector = this.editarDocenteFormGroup.controls.codSubsector.value;
    const nivelCodigo = codigo || this.editarDocenteFormGroup.controls.codNivel.value;
    const nivelId = this.listaNiveles.find(nivel => nivel.codigo == nivelCodigo)?.id;
    if (nivelId === undefined) return;
    this.subsectores = this.listaSubsectores.filter(subsector => subsector.nivelId == nivelId);
    const existeCodigoEnSubsectores = this.subsectores.find(subsector => subsector.codigo === codigoSubsector)?.codigo;
    if (!existeCodigoEnSubsectores || !codigoSubsector) {
      this.editarDocenteFormGroup.controls.codSubsector.setValue(this.subsectores[0].codigo);
    }
  }

  verificarAtributosNulos(): string[] {
    this.atributosNulosOVacios = Object.keys(this.editarDocenteFormGroup.controls)
      .filter(key => !this.editarDocenteFormGroup.get(key)?.value);
    if (!this.establecimientoSeleccionado) this.atributosNulosOVacios.push("Establecimiento**")
    return this.atributosNulosOVacios;
  }

  obtenerDatosFormulario() {
    this.fichaDocenteService.getEstablecimientos()
    .subscribe(
      { next: (response) => {
        if (response?.establecimientos) {
          this.listaRBD = response.establecimientos;
          this.listaEstados = response.estados;
          this.listaNiveles = response.niveles;
          this.listaSubsectores = response.subsectores;
          this.listaRBDFiltrada = this.listaRBD;
        }
        else {
          this.errorMensaje.show(null, "obtener los establecimientos pues no se encontraron datos");
        }
      },
        error: (error:any) => this.errorMensaje.show(error, "obtener los establecimientos.")
      }
    );
  }

  setInfoEditarDocente( rut: number, element: any) {
    this.modalEditarDocenteOn = false;
    this.subsectores = null;
    this.rbdControl.setValue(element.rbd);
    if(this.listaRBD) this.initEditar(element);
    this.acordeonesEditarDocente = [];
    this.editarInfoDocente = false;
    this.acordeonesEditarDocente.push(
      {
        content: this.editarDatosPersonales,
        id: "headingEditOne",
        title: "Edición datos personales",
        target: "#acordeonEditarUno",
        showFirst: true,
        keepOpen: true,
        context:{
          editarDocenteFormGroup:this.editarDocenteFormGroup,
          formularioDatosPersonales:this.formularioDatosPersonales
        },
        injector:null,
      });
    this.acordeonesEditarDocente.push(
      {
        content: this.editarDatosContacto,
        id: "headingEditTwo",
        title: "Edición datos de Contacto",
        target: "#acordeonEditarDos",
        showFirst: false,
        keepOpen: false,
        context:{
          editarDocenteFormGroup: this.editarDocenteFormGroup,
          formularioDatosContacto: this.formularioDatosContacto,
          estadoDocenteSeleccionado:this.estadoDocenteSeleccionado
        },
        injector:null,
      });

    this.acordeonesEditarDocente.push(
      {
        content: this.editarEstablecimiento,
        id: "headingEditThree",
        title: "Edición de Establecimiento",
        target: "#acordeonEditarTres",
        showFirst: false,
        keepOpen: false,
        context:{
          $implicit: this,
        },
        injector:null,
      });

      this.acordeonesEditarDocente.push(
        {
          content: this.editarInscripcion,
          id: "headingEditFour",
          title: "Edición de Datos de Inscripción",
          target: "#acordeonEditarCuatro",
          showFirst: false,
          keepOpen: false,
          context:{
            $implicit: this,
          },
          injector:null,
        });

    this.editarInfoDocente = true;
  }

  injectorFichaDocente = inject(Injector);

  menuDatosPersonalesAbierto(estado: boolean) {
    this.estaMenuDatosPersonalesAbierto = estado;
  }
  menuEdicionDatosPersonalesAbierto(estado: boolean) {
    this.estaMenuEdicionDatosPersonalesAbierto = estado;
  }

  docenteEstadoDatos(fecha: string) {
    return new Date (fecha).getTime() < this.fechaCorteValidacion.getTime();
  }

  initEditar(element: any) {
    const rbd: any = this.searchRbd(element.rbd);
    const nombreEstablecimiento = rbd.length>0 ? rbd[0].nombre : '';
    this.establecimientoSeleccionado = rbd.length>0 ? rbd[0] : null;
    this.setRbd(element.rbd);

    this.editarDocenteFormGroup.setValue({
      idRegistro: element.idRegistro,
      idValidacion: element.idValidacion,
      rutDocente: element.rutDocente,
      nombres: element.nombres,
      primerApellido: element.primerApellido,
      segundoApellido: element.segundoApellido,
      email: element.email,
      numeroContacto1: element.numeroContacto1,
      numeroContacto2: element.numeroContacto2,
      rbd: element.rbd,
      establecimiento: nombreEstablecimiento,
      estadoRegistro: element.estadoRegistro,
      codNivel: element.codNivel,
      codSubsector: element.codSubsector,
      motivoNoInscripcion: element.motivoNoInscripcion,
    });
    this.obtenerSubsectores(element.codNivel);
    this.estadoDocenteSeleccionado = element.estadoValidacion == "Validado";
    this.rutInfoSuspender = `${element.rutDocente} - ${element.dvDocente}`;
    this.modalEditarDocenteOn = true;
  }

  cerrarModalEdicion(){
    this.modalEditarDocenteOn = false;
  }

  setRbd(value: string){
    const resultado: any = this.searchRbd(value);
    let nombre = ''
    if (resultado.length > 0) {
      this.establecimientoSeleccionado = resultado[0];
      nombre = this.establecimientoSeleccionado.nombre;
    }

    this.editarDocenteFormGroup.setControl("establecimiento", new FormControl(nombre));
  }

  getRbd (value: string) {
    this.searchRbd(value);
  }

  searchRbd (value: string): Rbd | Rbd[] {
    let resultado:Rbd[] = null;
    const filterValue = value ? value.toString().trim(): '';
    this.establecimientoSeleccionado = null;

    if (!filterValue){
      this.listaRBDFiltrada = this.listaRBD.slice(0,5);
      return this.listaRBDFiltrada;
    }
    if (Number(filterValue)) {
      resultado = this.listaRBD.filter(
        establecimiento => establecimiento.rbd.toString().includes(value));
      if (resultado.length > 5) {
        resultado = resultado.slice(0,5);
      }
    }

    this.listaRBDFiltrada = resultado;
    return resultado;
  }


  getInfoSuspenderEximir( rut: number, element: any) {
    this.docenteSuspenderEximir = null;
    this.fichaDocenteService.infoSuspenderEximir(rut).
    subscribe({
      next: (response) => {
        if (response) {
          this.docenteSuspenderEximir = response;
          this.rutInfoSuspender = `${element.rutDocente} - ${element.dvDocente}`;
          this.nombreInfoSuspender = `${element.nombres} ${element.primerApellido} ${element.segundoApellido}`;
        }
      },
      error: (error:any) => {
      }
    });
  }

  guardarEdicionDocente() {
    let dataEdicion = this.editarDocenteFormGroup.getRawValue();
    dataEdicion.numeroContacto1 = Number(dataEdicion.numeroContacto1) ? dataEdicion.numeroContacto1 : 0;
    dataEdicion.numeroContacto2 = Number(dataEdicion.numeroContacto2) ? dataEdicion.numeroContacto2 : 0;
    dataEdicion.rbd = Number(this.rbdControl.value);
    const idEstado:number = this.listaEstados.find(item => item.nombreEstado == dataEdicion.estadoRegistro).id;
    dataEdicion.estadoRegistro = String(idEstado);
    this.fichaDocenteService.guardarEdicionDocente(dataEdicion)
    .subscribe({
      next: (response) => {
          if(response) {
            Toast.fire(
              "Edición exitosa",
              "El proceso de edición de docente ha sido efectuado exitosamente",
              "success");
          }
          this.filtrar();
      },
      error: (error:any) => {
        this.errorMensaje.show(error, "editar el docente");
      }
    });

  }

  pageChanged(event: PageEvent) {
    this.options.page = event != null ? (event.pageIndex + 1) : 1;
    this.options.size = event != null ? event.pageSize : 10;
    this.getDocentes();
  }

  public filtrar(): void {
    this.options.page = 1;
    this.options.size = 10;
    this.getDocentes();
  }

  public limpiar(): void {
    this.filtros = new FichaDocenteFilter();
    this.filtrar();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  obtenerDocente(element: Docente) {
    this.docenteSeleccionadoModal = null;
    this.docenteSeleccionadoModal = element;
    this.mostrarInfoDocente = false;
    this.acordeonesInfoDocente = [];
    this.docenteSeleccionadoModal.ultimoLogin = 'Consultando detalles de docente';
    const rut = this.docenteSeleccionadoModal.rutDocente;
    const idDocente = this.docenteSeleccionadoModal.idRegistro;
    this.validationDataSection = this.validationDataSectionFull;
    if (!this.docenteSeleccionadoModal.solicitaCambioSubs) {
      this.validationDataSection = this.validationDataSectionWithoutChanges;
    }
    this.fichaDocenteService.getDocenteDetalles(idDocente, rut)
    .subscribe(
      { next: (response:any) => {
        if (response) {
          this.docenteSeleccionadoModal.nivelPrevio = response.nivelPrevio;
          this.docenteSeleccionadoModal.ultimoLogin = response.ultimoLogin;
          this.docenteSeleccionadoModal.subsectorPrevio = response.subsectorPrevio;
          this.docenteSeleccionadoModal.fechaUltEstado = response.fechaUltEstado;
          this.docenteSeleccionadoModal.solicitaCambioSubsector = response.solicitaCambioSubsector;
          this.docenteSeleccionadoModal.nivelSolicitado = response.nivelSolicitado;
          this.docenteSeleccionadoModal.subsectorSolicitado = response.subsectorSolicitado;
          this.docenteSeleccionadoModal.estadoConfirmacionSubsector = response.estadoConfirmacionSubsector;
          this.docenteSeleccionadoModal.aplazamiento = response.aplazamiento;
          this.docenteSeleccionadoModal.beneficio19n = response.beneficio19n;
          this.docenteSeleccionadoModal.agnoPortafolioCorregido = response.agnoPortafolioCorregido;
          this.docenteSeleccionadoModal.tramos = response.tramos;
          this.docenteSeleccionadoModal.nombreNivelPrevio = response.nombreNivelPrevio;
          this.docenteSeleccionadoModal.nombreNivelSolicitado = response.nombreNivelSolicitado;
          this.docenteSeleccionadoModal.nombreSubsectorPrevio = response.nombreSubsectorPrevio;
          this.docenteSeleccionadoModal.nombreSubsectorSolicitado = response.nombreSubsectorSolicitado;
          this.docenteSeleccionadoModal.codDebeRendir = response.debeRendir;
          this.docenteSeleccionadoModal.fechaInicioSolicitud = response.fechaInicioSolicitud;
          this.docenteSeleccionadoModal.fechaResultado = response.fechaResultado;
          this.docenteSeleccionadoModal.fechaValidacion = response.fechaInicioSolicitud;
          this.docenteSeleccionadoModal.fechaSolicitud = response.fechaSolicitud;
        }
        else {
          this.docenteSeleccionadoModal.ultimoLogin = "No ha ingresado a la plataforma"
        }
      },
        error: (error:any) => {}
      });
    this.doneTimeline = false;
    this.fichaDocenteService.getHistorialValidacion(rut)
      .subscribe(
        {
          next: (response:any) => {
            this.doneTimeline = true;
            if (!response || response.length == 0) response = null;
            this.historialValidacion = response;
              this.acordeonesInfoDocente.push(
                {
                  content: this.infoDatosPersonales,
                  id: "headingOne",
                  title: "Datos Personales",
                  target: "#acordeonUno",
                  showFirst: true,
                  keepOpen: false,
                  context:{
                    personalDataSection:this.personalDataSection,
                    docenteSeleccionadoModal:this.docenteSeleccionadoModal
                  },
                  injector:null,
                });
              this.acordeonesInfoDocente.push(
                {
                  content: this.infoDatosEstablecimiento,
                  id: "headingTwo",
                  title: "Datos de Establecimiento",
                  target: "#acordeonDos",
                  showFirst: false,
                  keepOpen: false,
                  context:{
                    establishmentDataSection: this.establishmentDataSection,
                    docenteSeleccionadoModal: this.docenteSeleccionadoModal
                  },
                  injector:null,
                });
                this.acordeonesInfoDocente.push(
                  {
                    content: this.infoDatosInscripcion,
                    id: "headingThree",
                    title: "Datos de Inscripción",
                    target: "#acordeonTres",
                    showFirst: false,
                    keepOpen: false,
                    context:{
                      inscriptionDataSection: this.inscriptionDataSection,
                      docenteSeleccionadoModal: this.docenteSeleccionadoModal
                    },
                    injector:null,
                  });

                  this.acordeonesInfoDocente.push(
                    {
                      content: this.infoValidacion,
                      id: "headingFour",
                      title: "Ficha de Validación de Datos y Beneficios",
                      target: "#acordeonCuatro",
                      showFirst: false,
                      keepOpen: false,
                      context:{
                        validationDataSection: this.validationDataSection,
                        esFecha: this.esFecha,
                        docenteSeleccionadoModal: this.docenteSeleccionadoModal,
                        historialValidacion: response,
                      },
                      injector:null,
                    });
                const titulohistorial = !response ? "(0)" : "(" +response.length + ")";
                this.acordeonesInfoDocente.push(
                  {
                    content: this.infoHistorial,
                    id: "headingSix",
                    title: "Historial de Validación de datos y actualizaciones " + titulohistorial,
                    target: "#acordeonSeis",
                    showFirst: false,
                    keepOpen: false,
                    context:{
                      historialValidacion: response,
                    },
                    injector:null,
                  });

                this.acordeonesInfoDocente.push(
                  {
                    content: this.infoEximirSuspender,
                    id: "headingFive",
                    title: "Datos de Eximir Suspender",
                    target: "#acordeonCinco",
                    showFirst: false,
                    keepOpen: false,
                    context:{
                      exemptSuspendDataSection: this.exemptSuspendDataSection,
                      docenteSeleccionadoModal: this.docenteSeleccionadoModal
                    },
                    injector:null,
                  });


          },
          error: (err:any) => {

          },
      });



      this.mostrarInfoDocente = true;
  }

  esFecha(atributo: string): boolean {
    return atributo.toLowerCase().includes('fecha');
  }

  setUrl(rut: string) {
    this.isLoading = true;
    this.iframe.nativeElement.contentWindow.location.replace("about:blank");
    this.fichaDocenteService.obtenerToken(rut).subscribe({
      next:(response)=>{
        if(response.hasOwnProperty("access_token") ){
          this.fichaDocenteService.obtenerArchivo(response.access_token, rut).subscribe({
            next: (response) => {
              this.isLoading = false;
              const url = URL.createObjectURL(response)+"#toolbar=0";
              this.iframe.nativeElement.contentWindow.location.replace(url);
            },
            error: (error) => {
              if (error.status === 409) {
                this.isLoading = false;
                this.mensajeError = true;
                this.errorMensaje.showWarning(error,"El docente no se encuentra validado");
                return;
              }
              this.isLoading = false;
              this.mensajeError = true;
              this.errorMensaje.show(error, "obtener el documento para el usuario " + rut);

            }
        });

        }

      },
      error:(error) => {
        this.isLoading = false;
        this.mensajeError = true;
        this.errorMensaje.show(error, " obtener el usuario en docentemás");

      }
  });
  }

  getArchivoObligatorio(archivos:string){
    this.fichaDocenteService.obtenerArchivoObligatorio(archivos).subscribe({
      next: (response: HttpResponse<any>) => {
        if (response.type === HttpEventType.Response) {
          const link = response.body.data;
          window.open(link);
        }
      },
      error: (error: any) => {
        this.errorMensaje.show(error, " obtener el archivo");
      },

    });
  }

  formatearHoraChile(hora: string): string {
    const format = 'YYYY-MM-DD HH:mm:ss.SSSSS';
    const momentHora = moment.utc(hora, format).tz('America/Santiago');

    // Formatear la hora a un formato legible
    return momentHora.format('YYYY-MM-DD HH:mm:ss');
  }

  descarga() {
    const filename = 'reporte Ficha docente.csv';
    this.isLoading = true;
    this.reporteService.descargaReporte("2024","Ficha Docente", "puntoycoma")
      .subscribe(

          value => {
            if (value.type === HttpEventType.Response) {
              const blob = new Blob([value.body], { type: 'text/csv' });
              const downloadUrl = URL.createObjectURL(blob);
              const a = document.createElement('a');
              document.body.appendChild(a);
              a.setAttribute('style', 'display: none');
              a.href = downloadUrl;
              a.download = filename;
              a.click();
              URL.revokeObjectURL(downloadUrl);
              a.remove();
              this.isLoading = false;
              Toast.fire("Descarga Finalizada", "La descarga de reporte ha finalizado.", "success");
            }
          },
          error => {
            this.isLoading = false;
          }

      );
  }
}
