import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { QaGrabacionesService } from "../../services/qaGrabacionesService";
import { ImageModalComponent } from "../../other-components/image-modal/image-modal.component";
import { formatTimeFromSeconds } from '../../../../../utils/functions/time-functions';
import Swal from 'sweetalert2';
import {AuthService} from "../../../../../base/services/auth.service";
import {environment} from "../../../../../../environments/environment";
import {AlertsService} from "../../../../../base/services/alerts.service";
import {getTipoEventoById} from "../../constants/eventosRevision";
import {MotivoDTO} from "../../dto/motivoDTO";
import {CategoriaMotivoDTO} from "../../dto/categoriaMotivoDTO";
import {ProblemaCorreccionDTO} from "../../dto/problemaCorreccionDTO";
import {CorreccionDTO} from "../../dto/correccionDTO";
import {DetalleRevisionDTO} from "../../dto/detallesRevisionDTO";
import {MOTIVOS, MOTIVOS_CAMBIO_VALOR} from "../../constants/motivos-constants";

class CustomDataUpdate {
  VIDEO: boolean = false;
  SCREENSHOTS: boolean = false;
  CONFLICTS: boolean = false;
  HISTORY: boolean = false;
  DETAILS: boolean = false;
  CORRECTIONS: boolean = false;
}


@Component({
  selector: 'app-detalle-revision',
  templateUrl: './detalle-revision.component.html',
  styleUrls: ['./detalle-revision.component.css']
})
export class DetalleRevisionComponent implements OnInit {

  protected readonly formatTimeFromSeconds = formatTimeFromSeconds;
  motivos: MotivoDTO[] = [];

  customDataUpdate: CustomDataUpdate = null;

  isLoading: boolean = true;
  isCustomUpdating: boolean = false
  isSaving: boolean = false;

  deletingCorrection: boolean = false;
  revisionId: number;
  detalleRevision: DetalleRevisionDTO;

  correccion: CorreccionDTO;
  hideBottomButtons: boolean = false;


  bypassOperatorAssignment: boolean = environment.features.revisionesGrabaciones.bypassOperatorAssignment;
  bypassRevisionConflicts: boolean = environment.features.revisionesGrabaciones.bypassRevisionConfitcs;
  reopenRevisionEnabled: boolean = environment.features.revisionesGrabaciones.reopenRevision;

  errorLoading: boolean = false;

  readonly: boolean = !this.bypassOperatorAssignment && true;
  revisionAssignedToAnotherOperator: boolean = false;
  readonlyUser: boolean = false;

  isCreatingNewCorrection = false;
  isEditingCorrection = false;
  selectedImage: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected service: QaGrabacionesService,
    protected authService: AuthService,
    public dialog: MatDialog,
    protected alerts: AlertsService,
  ) {
    this.revisionId = data.id;
  }

  ngOnInit(): void {
    this.readonlyUser = this.authService.getUserGroup().includes("READER_GRABACIONES");
    this.service.obtenerCategoriasYMotivos().subscribe({
      next: (data: CategoriaMotivoDTO[]) => {
        this.motivos = data.flatMap(categoria => categoria.motivos);
        this.loadRevisionDetails();

      },
      error: (error) => {
        this.alerts.showExceptionAlert(error);
        console.error('Error al cargar motivos y categorías:', error);
        this.isLoading = false;
      }
    });

    if(this.readonlyUser){
      this.readonly = true;
    }
  }

  getMotivoById(id: number): MotivoDTO | null {
    return this.motivos.find(motivo => motivo.id === id) || null;
  }

  loadRevisionDetails(options: {customDataUpdate: boolean} | null = null) {

    if(options?.customDataUpdate){
      this.isCustomUpdating = true;
    }

    this.isLoading = true;

    if((this.isCustomUpdating && this.customDataUpdate.CORRECTIONS) || !this.isCustomUpdating) {
      this.correccion = null;
    }

    if(!options?.customDataUpdate){
      this.detalleRevision = null;
    }

    this.readonly = this.readonlyUser;

    this.service.getDetalleRevision(this.revisionId).subscribe({
      next: (detallesRevision) => {

        if(this.isCustomUpdating){
          if(this.customDataUpdate.VIDEO){
            this.detalleRevision.videoURL = detallesRevision.videoURL
          }
          if(this.customDataUpdate.DETAILS){
            this.detalleRevision.estadoId = detallesRevision.estadoId
            this.detalleRevision.rutDocente = detallesRevision.rutDocente
            this.detalleRevision.ordenCorrelativo = detallesRevision.ordenCorrelativo
            this.detalleRevision.duracionTotalVideos = detallesRevision.duracionTotalVideos
            this.detalleRevision.archivado = detallesRevision.archivado
            this.detalleRevision.agrupacionAsignatura  = detallesRevision.agrupacionAsignatura
          }
          if(this.customDataUpdate.HISTORY){
            this.detalleRevision.historial = detallesRevision.historial
          }
          if(this.customDataUpdate.SCREENSHOTS){
            this.detalleRevision.screenshots = detallesRevision.screenshots
          }
          if(this.customDataUpdate.CONFLICTS){
            this.detalleRevision.conflictos = detallesRevision.conflictos
          }
        }
        else{
          this.detalleRevision = detallesRevision;
        }
          this.service.obtenerCorreccionesPorRevision(this.revisionId).subscribe({
          next: (correction) => {
            if((this.isCustomUpdating && this.customDataUpdate.CORRECTIONS) || !this.isCustomUpdating) {
              this.correccion = correction;
            }
            this.revisionAssignedToAnotherOperator = !this.bypassOperatorAssignment && (this.detalleRevision.operadorId !== null && this.detalleRevision.operadorId !== this.authService.getEmailUser());
            this.readonly = this.readonlyUser || this.revisionAssignedToAnotherOperator;
            if(this.authService.containRol(['ADM_GRABACIONES', 'ROLE_ADMIN'])){
              this.readonly = false;
            }
            this.isLoading = false;
            this.isCustomUpdating = false;
          },
          error: (error: Error) => {
            this.errorLoading = true;
            console.error(error);
            this.isLoading = false;
            this.isCustomUpdating = false;
            this.alerts.showExceptionAlert(error)
          }
        });
      },
      error: (error: Error) => {
        this.errorLoading = true;
        this.isLoading = false;
        this.isCustomUpdating = false;
        this.closeModal();
        this.alerts.showExceptionAlert(error)
      }
    });
  }

  openModal(screenshot: any): void {
    this.selectedImage = screenshot;
    this.dialog.open(ImageModalComponent, {
      data: {
        image: this.selectedImage.presignedURL,
        frameNumber: this.selectedImage.frameNumber,
        timeSeconds: this.selectedImage.timeSeconds
      },
      width: 'auto',
      height: 'auto',
      maxWidth: 'auto',
      maxHeight: 'auto',
    });
  }

  closeModal(): void {
    this.dialog.closeAll();
  }


  startNewCorrection() {
    this.isCreatingNewCorrection = true;
    this.isEditingCorrection = false;
  }

  editCorrection(correctionId: number) {
    this.isCreatingNewCorrection = false;
    this.isEditingCorrection = true;
  }

  cancelNewCorrection() {
    this.isCreatingNewCorrection = false;
    this.isEditingCorrection = false;
  }

  get canReOpenRevision(){
    return this.reopenRevisionEnabled && (this.detalleRevision?.estadoId == 2 || this.detalleRevision?.estadoId == 3 || this.detalleRevision?.estadoId == 4) && this.authService.containRol(['ADM_GRABACIONES', 'ROLE_ADMIN'])
  }

  reopenRevision() {
    let hasUnsolvableProblems = false;
    for (let problema of (this.correccion?.problemas || []) as ProblemaCorreccionDTO[]) {
      if (!this.getMotivoById(problema.idMotivo).solucionable) {
        hasUnsolvableProblems = true;
        break;
      }
    }

    const mainMessage = 'Está a punto de reabrir la revisión.';

    const warningMessage = 'Esta acción revertirá el estado actual de la revisión. Por favor, revise cuidadosamente antes de proceder. Asegúrese de que comprende las posibles consecuencias, ya que errores en este proceso pueden afectar el flujo de trabajo y generar problemas adicionales.';

    let detailMessage = hasUnsolvableProblems
      ? '<div class="alert alert-warning mt-3"><b>Nota:</b> La revisión presenta <b>problemas no solucionables</b>. Al reabrir, deberá gestionar estos problemas nuevamente.</div>'
      : '';

    if (this.detalleRevision.operadorId == null) {
      detailMessage += `<div class="alert alert-info mt-3"><b>Advertencia:</b> Todas las revisiones del docente actual serán reasignadas al operador ${this.authService.getEmailUser()} al reabrir la revisión.</div>`
    }

    this.hideBottomButtons = true;
    Swal.fire({
      title: 'Confirmación de Reapertura',
      html: `
    <div class="swal-section">
      <div class="swal-main-message">
        ${mainMessage}
      </div>
      <div class="swal-warning-message alert alert-danger mt-2">
        ${warningMessage}
      </div>
      ${detailMessage}
    </div>
  `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, reabrir',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md text-sm flex items-center ng-star-inserted',
        cancelButton: 'bg-gray-300 text-black py-2 px-4 rounded-md text-sm flex items-center ng-star-inserted ml-2'
      },
      buttonsStyling: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.isSaving = true;
        this.service.reabrirRevision(this.revisionId).subscribe({
          next: () => {
            //this.closeModal();
            this.hideBottomButtons = false;
            this.isSaving = false;
            this.loadRevisionDetails()
          },
          error: (error) => {
            this.isSaving = false;
            this.alerts.showExceptionAlert(error)
            this.hideBottomButtons = false;
            console.error(error);
          }
        });
      } else {
        this.hideBottomButtons = false;
      }
    });
  }

  closeRevision() {
    let hasUnsolvableProblems = false;
    for (let problema of (this.correccion?.problemas || []) as ProblemaCorreccionDTO[]) {
      if (!this.getMotivoById(problema.idMotivo).solucionable) {
        hasUnsolvableProblems = true;
        break;
      }
    }

    const mainMessage = this.correccion !== null
      ? 'Está a punto de finalizar la revisión con <b>correcciones</b>.'
      : 'Está a punto de finalizar la revisión <b>sin correcciones</b>.';

    const warningMessage = 'Por favor, revise cuidadosamente antes de proceder.';

    let detailMessage = hasUnsolvableProblems
      ? '<div class="alert alert-warning mt-3"><b>Nota:</b> La revisión presenta <b>problemas no solucionables</b>. Al finalizar, será marcada como <b>fallida</b> y <b>archivada</b>.</div>'
      : '';

    if(!this.correccion && this.detalleRevision.operadorId == null) {
      detailMessage += `<div class="alert alert-info mt-3"><b>Advertencia:</b> Todas las revisiones del docente actual serán asignadas al operador ${this.authService.getEmailUser()} al finalizar la revisión sin correcciones.</div>`
    }

    this.hideBottomButtons = true;
    Swal.fire({
      title: 'Confirmación de Finalización',
      html: `
      <div class="swal-section">
        <div class="swal-main-message">
          ${mainMessage}
        </div>
        <div class="swal-warning-message alert alert-danger mt-2">
          ${warningMessage}
        </div>
        ${detailMessage}
      </div>
    `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, finalizar',
      cancelButtonText: 'Cancelar',
      customClass: {
        confirmButton: 'bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md text-sm flex items-center ng-star-inserted',
        cancelButton: 'bg-gray-300 text-black py-2 px-4 rounded-md text-sm flex items-center ng-star-inserted ml-2'
      },
      buttonsStyling: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.isSaving = true;
        this.service.cerrarRevision(this.revisionId).subscribe({
          next: () => {
            this.loadRevisionDetails();
            this.hideBottomButtons = false;
            this.isSaving = false;
          },
          error: (error) => {
            this.alerts.showExceptionAlert(error)
            this.hideBottomButtons = false;
            this.isSaving = false;
            console.error(error);
          }
        });
      } else {
        this.hideBottomButtons = false;
        this.isSaving = false;
      }
    });
  }

  deleteCorrection(id: number): void {
    Swal.fire({
      title: '¿Estás completamente seguro?',
      text: `Estás a punto de eliminar la incidencia #${id}. Esta acción es irreversible y no podrás recuperar los datos una vez eliminados. Por favor, asegúrate de que realmente deseas continuar con esta operación.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar definitivamente',
      cancelButtonText: 'Cancelar',
      focusCancel: true
    }).then((result) => {
      if (result.isConfirmed) {
        this.deletingCorrection = true;
        this.service.deleteCorreccion(id).subscribe({
          next: () => {
            Swal.fire({
              title: 'Incidencia eliminada',
              text: `La incidencia #${id} ha sido eliminada de forma permanente.`,
              icon: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Entendido'
            });
            this.correccion = null;
            this.deletingCorrection = false;
            this.customDataUpdate = new CustomDataUpdate();
            this.customDataUpdate.HISTORY = true;
            this.customDataUpdate.CONFLICTS = true;
            this.customDataUpdate.CORRECTIONS = true;
            this.loadRevisionDetails({
              customDataUpdate: true
            });          },
          error: (error) => {
            this.alerts.showExceptionAlert(error)
            this.deletingCorrection = false;
          }
        });
      }
    });
  }

  saveCorrection(correccionData: { problemas: ProblemaCorreccionDTO[] }) {
    if(this.isCreatingNewCorrection) {
      this.service.iniciarCorreccion(this.revisionId, correccionData).subscribe({
        next: (nuevaCorreccion) => {
          this.correccion = nuevaCorreccion;
          this.cancelNewCorrection();
          this.customDataUpdate = new CustomDataUpdate();
          this.customDataUpdate.HISTORY = true;
          this.customDataUpdate.CONFLICTS = true;
          this.customDataUpdate.CORRECTIONS = true;

          this.loadRevisionDetails({
            customDataUpdate: true
          });
          //this.loadRevisionDetails();
        },
        error: (error: Error) => {
          this.alerts.showExceptionAlert(error)
          this.cancelNewCorrection();
          console.error(error);
        }
      });
    } else if (this.isEditingCorrection) {
      this.service.editarCorreccion(this.revisionId, this.correccion.id, correccionData).subscribe({
        next: (nuevaCorreccion) => {
          this.correccion = nuevaCorreccion;
          this.cancelNewCorrection();
          this.customDataUpdate = new CustomDataUpdate();
          this.customDataUpdate.HISTORY = true;
          this.customDataUpdate.CONFLICTS = true;
          this.customDataUpdate.CORRECTIONS = true;

          this.loadRevisionDetails({
            customDataUpdate: true
          });
          //this.loadRevisionDetails();
        },
        error: (error: Error) => {
          this.alerts.showExceptionAlert(error)
          console.error(error);
          this.cancelNewCorrection();
        }
      });
    }

  }

  formatTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    const milliseconds = Math.floor((seconds % 1) * 100);

    const hoursString = hours.toString().padStart(2, '0');
    const minutesString = minutes.toString().padStart(2, '0');
    const secondsString = secs.toString().padStart(2, '0');
    const millisecondsString = milliseconds.toString().padStart(2, '0');

    return `${hoursString}:${minutesString}:${secondsString}`;
  }

  onRubricaAnswerSaved(event: any) {
    this.customDataUpdate = new CustomDataUpdate();
    this.customDataUpdate.CONFLICTS = true;

    this.loadRevisionDetails({
      customDataUpdate: true
    });
  }

  protected readonly getTipoEventoById = getTipoEventoById;
  protected readonly MOTIVOS = MOTIVOS;
  protected readonly MOTIVOS_CAMBIO_VALOR = MOTIVOS_CAMBIO_VALOR;
}
