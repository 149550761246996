import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { RubricaRevisionService } from "../../../services/rubrica-revision.service";
import { AlertsService } from "../../../../../../base/services/alerts.service";
import { Toast } from 'src/app/base/services/toast';
import { AuthService } from "../../../../../../base/services/auth.service";

interface AlternativasPregunta {
  id: number;
  respuesta: string;
}

interface CondicionesParaMostrar {
  "rubricaId": number,
  "rubricaCondicionanteId": number,
  "respuestaRequeridaId": number
}

interface Pregunta {
  rubricaId: number;
  bloqueado: boolean;
  pregunta: string;
  descripcion: string;
  alternativas: AlternativasPregunta[];
  condicionesParaMostrar: CondicionesParaMostrar[];
  respuesta: { idRubrica: number; idRespuesta: number } | null;
  numero?: number | string;
}

interface Respuesta {
  rubricaId: number;
  respuesta: number | null;
}

@Component({
  selector: 'app-rubrica-revision',
  templateUrl: './rubrica-revision.component.html',
  styleUrls: ['./rubrica-revision.component.css']
})
export class RubricaRevisionComponent implements OnInit {
  @Input() rutDocente!: number;
  @Input() asignadoAEsteOperador: boolean = true;
  @Output() successfulSaved = new EventEmitter<void>();

  isSaving = false;
  preguntas: Pregunta[] = [];
  respuestas: Respuesta[] = [];
  isLoading = false;
  readonlyUser = false;
  readonly = false;
  respuestaTemporal: { rubricaId: number; respuesta: number | null } | null = null;

  preguntasAgrupadas: { principal: Pregunta; condicionadas: Pregunta[] }[] = [];


  constructor(
    private rubricaService: RubricaRevisionService,
    private alerts: AlertsService,
    protected authService: AuthService
  ) {}

  ngOnInit(): void {
    this.readonlyUser = this.authService.getUserGroup().includes("READER_GRABACIONES");
    if (this.readonlyUser) {
      this.readonly = true;
    }
    this.cargarPreguntas();
  }

  cargarPreguntas(): void {
    this.isLoading = true;
    this.rubricaService.obtenerRubricaPorRutDocente(this.rutDocente).subscribe(
      (preguntas: Pregunta[]) => {
        this.preguntas = preguntas;
        this.inicializarRespuestas();
        this.preguntasAgrupadas = this.agrupacionPreguntas(); // Agrupar preguntas para mostrarlas como corresponde
        this.isLoading = false;
      },
      (error: any) => {
        this.alerts.showExceptionAlert(error);
        this.isLoading = false;
      }
    );
  }

  estaRespondida(rubricaId: number): boolean {
    const respuesta = this.respuestas.find(res => res.rubricaId === rubricaId);
    return respuesta?.respuesta !== null && respuesta?.respuesta !== 0;
  }

  estaGuardando(rubricaId: number): boolean {
    return this.isSaving && this.respuestaTemporal?.rubricaId === rubricaId;
  }


  inicializarRespuestas(): void {
    this.respuestas = this.preguntas.map((pregunta) => ({
      rubricaId: pregunta.rubricaId,
      respuesta: pregunta.respuesta ? pregunta.respuesta.idRespuesta : null
    }));
  }

  getRespuesta(rubricaId: number): number | null {
    const respuesta = this.respuestas.find(res => res.rubricaId === rubricaId);
    return respuesta ? respuesta.respuesta : null;
  }

  mostrarRubrica(rubricaId: number){
    const pregunta = this.preguntas.find(pregunta => pregunta.rubricaId === rubricaId)
    if(pregunta?.condicionesParaMostrar?.length > 0){
        for(let condicionParaMostrar of pregunta.condicionesParaMostrar){
          let respuesta = this.respuestas.find(respuesta => respuesta.rubricaId === condicionParaMostrar.respuestaRequeridaId)
          if(respuesta?.respuesta != condicionParaMostrar.respuestaRequeridaId ){
            return false;
          }
        }
    }
    return true;
  }

  agrupacionPreguntas(): { principal: Pregunta & { numero: number }; condicionadas: (Pregunta & { numero: string })[] }[] {
    let contadorPrincipal = 1; // Inicia el contador de preguntas principales

    return this.preguntas.reduce((grupos, pregunta) => {
      // Verificar si la pregunta es principal
      const esCondicionada = this.preguntas.some((p) =>
        p.condicionesParaMostrar.some((cond) => cond.rubricaId === pregunta.rubricaId)
      );

      if (!esCondicionada) {
        // Asignar número a la pregunta principal
        const principalConNumero = { ...pregunta, numero: contadorPrincipal++ };

        // Obtener las preguntas condicionadas
        let contadorCondicionada = 65; // Código ASCII de 'A'
        const condicionadas = this.preguntas
          .filter((p) =>
            p.condicionesParaMostrar.some((cond) => cond.rubricaCondicionanteId === pregunta.rubricaId)
          )
          .map(condicionada => ({
            ...condicionada,
            numero: String.fromCharCode(contadorCondicionada++) // Asigna letras A, B, C...
          }));

        grupos.push({ principal: principalConNumero, condicionadas });
      }

      return grupos;
    }, [] as { principal: Pregunta & { numero: number }; condicionadas: (Pregunta & { numero: string })[] }[]);
  }

  setRespuesta(rubricaId: number, idRespuesta: number): void {
    if (this.readonly) {
      return;
    }

    // Guarda el valor actual antes de actualizarlo
    this.respuestaTemporal = {
      rubricaId: rubricaId,
      respuesta: this.getRespuesta(rubricaId)
    };

    const respuesta = this.respuestas.find(res => res.rubricaId === rubricaId);
    if (respuesta) {
      respuesta.respuesta = idRespuesta;
    } else {
      this.respuestas.push({ rubricaId, respuesta: idRespuesta });
    }

    this.guardarRespuestas();
  }

  guardarRespuestas(): void {

    if(this.preguntas.filter(pregunta => pregunta.bloqueado == true).length > 0){
      const respuesta = this.respuestas.find(res => res.rubricaId === this.respuestaTemporal!.rubricaId);
      if (respuesta) {
        respuesta.respuesta = this.respuestaTemporal.respuesta;
      }
      this.respuestaTemporal = null; // Limpia la respuesta temporal después de restaurar
      return;
    }

    this.isSaving = true;
    const respuestasFormatoCorrecto = this.respuestas
      .filter((respuesta) => respuesta.respuesta !== null && respuesta.respuesta !== 0)
      .map((respuesta) => ({
        idRubrica: respuesta.rubricaId,
        idRespuesta: respuesta.respuesta as number
      }));

    this.rubricaService.guardarRespuestas(this.rutDocente, respuestasFormatoCorrecto).subscribe(
      () => {
        this.successfulSaved.emit()
        this.isSaving = false;
        Toast.fire("Guardado", "Respuestas guardadas exitosamente.", "success");
        this.respuestaTemporal = null; // Limpia la respuesta temporal si el guardado fue exitoso
      },
      (error: any) => {
        this.alerts.showExceptionAlert(error);
        this.isSaving = false;

        // Restaura la respuesta original si hay un error
        if (this.respuestaTemporal) {
          const respuesta = this.respuestas.find(res => res.rubricaId === this.respuestaTemporal!.rubricaId);
          if (respuesta) {
            respuesta.respuesta = this.respuestaTemporal.respuesta;
          }
          this.respuestaTemporal = null; // Limpia la respuesta temporal después de restaurar
        }
      }
    );
  }
}
